import * as React from 'react';
import {NavigateParams} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View, Image, Text, Alert, TextInput} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import NavBarSeller from '../components/NavBarSeller';
import lupa_icon from '../assets/images/icons/magnifying-glass-solid-white.png';
import { getSearchOrder, getCreditos} from '../services/ApiServices';
import { getData } from '../services/StorageService';

export default class ReceiveOrders extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/', params: null},
            styles : styles,
            user: {},
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            orderCode: "",
            isLoading : false,
            creditos: 0
        }
    }

    componentDidMount(){
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState();
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})

        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});

        getCreditos(url, JSON.parse(user)._id).then(res => {
            this.setState({creditos: res});
        })
    }

    submitOrder(){
        this.setState({isLoading : true});
        if(this.state.orderCode.length < 6){
            this.setState({isLoading : false});
            alert("Código invalido!");
            Alert.alert("Código invalido!");
        }
        else if(this.state.user.vendedorApp && this.state.creditos == 0){
            this.setState({isLoading : false});
            alert("Você não possui créditos para receber pedidos");
            Alert.alert("Você não possui créditos para receber pedidos!");
        }
        else{
            getSearchOrder(this.state.currentUrl, this.state.orderCode).then(res => {
                this.setState({isLoading : false});
                alert(res.msg);
                Alert.alert(res.msg);
                if(res.pedido){
                    this.setState({redirect : {status: true, value: 'PaymentSeller', params: {order: res.pedido}}})
                }
            })
         }

         getCreditos(this.state.currentUrl, this.state.user._id).then(res => {
            this.setState({creditos: res});
        })

    }

    render() {
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={this.state.redirect.params}></NavigateParams>  : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Receber pedidos</Text>
                    {/* <Image source={lupa_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
                </View>
                <View style={{width: '100%', height: 96,...this.state.styles.col, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <TextInput style={{...this.state.styles.mt8, width: '90%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                            placeholder='Codigo do pedido'
                            onChangeText={(value) => {this.setState({orderCode : value})}}
                            value={this.state.orderCode}
                            keyboardType="default"
                            maxLength={7}
                    ></TextInput>
                    <View style={{width: '100%', ...this.state.styles.mt8, paddingLeft: 32, paddingRight: 32}}>
                        <ButtonComponent icon={lupa_icon} disabled={this.state.user.vendedorApp && this.state.creditos == 0} action={() => this.submitOrder()} variant="primary_filled">Buscar</ButtonComponent>
                    </View>  
                </View>      
            </NavBarSeller>
            )
    }
}
