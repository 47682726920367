import * as React from 'react';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,Image, TextInput, Text} from 'react-native';
import NavBar from '../components/NavBar';
import user_icon from '../assets/images/icons/user-solid.png';
import { getData } from '../services/StorageService';
export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            systemColor: 'white',
            user: {},
            currentSystem: "",
            phone: "",
        }
    }

    componentDidMount(){
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        getData("current_system").then(res => {
            this.setState({currentSystem: res});
            getData(res+"_user").then(res => this.setState({user : JSON.parse(res)}));
        });
    }
    setPhone(value){
        if(value.length > this.state.phone.length){
            let subMask = "?? ????? ????";
            value.split('').map((character,index) => {
                if(character != ' ' && !isNaN(character)){
                    subMask = subMask.replace('?', character);
                    if(index == value.length - 1){
                        this.setState({phone : subMask.split('?')[0]});
                    }
                }

            });
        }
        else{
            this.setState({phone : value})
        }
    }

    render() {
        return (
            <NavBar navigation={this.props.navigation}  >
            <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Perfil</Text>
                {/* <Image source={user_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
            </View>
            <View style={{...this.state.styles.container, padding : 32, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Nome'
                        value={this.state.user?.nome}
                        keyboardType="default"
                        maxLength={15}
                        editable={false}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} placeholder='WhatsApp'
                        onChangeText={(value) => {this.setPhone(value)}}
                        value={this.state.user?.telefone}
                        keyboardType="numeric"
                        maxLength={13}
                        editable={false}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='email'
                        value={this.state.user?.email}
                        keyboardType="default"
                        editable={false}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Cidade'
                        value={this.state.user?.cidade}
                        keyboardType="default"
                        maxLength={15}
                        editable={false}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Estado'
                        value={this.state.user?.estado}
                        keyboardType="default"
                        maxLength={10}
                        editable={false}
                ></TextInput>
            </View>
            </NavBar>
            )
    }
}
