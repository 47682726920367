import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity} from 'react-native';
import CardReward from './CardReward';
export default class ModalReward extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
        }
    }

    render() {

        return (
            <TouchableOpacity onPress={this.props.action}  style={{...this.state.styles.container, position: 'absolute', zIndex: 3000, width: '100%', maxWidth: 480, height: '100%', backgroundColor: 'rgba(1,1,1,0.3)'}}>
            <View style={{position: 'fixed', zIndex: 3001, top: 128,  width: '100%', maxWidth: 480, height: 280, backgroundColor: 'white', borderRadius: 6, display: 'flex', flexDirection: 'column'}}>
                <CardReward reward={this.props.currentReward}></CardReward>
            </View>
        </TouchableOpacity>  
        )
    }
}
