import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,ScrollView, TouchableOpacity, Image, Text} from 'react-native';
import NavBar from '../components/NavBar';
import note_icon from '../assets/images/icons/note-sticky-regular.png';
import { getData } from '../services/StorageService';
import { getCurrentRewards, getHistorics, getHistoricsRewards, getHistoricsRewardsInterval, getStatusStore } from '../services/ApiServices';
import moment from "moment";
import lupa_icon from '../assets/images/icons/magnifying-glass-solid.png';
import ModalReward from '../components/ModalReward';
import TopBar from '../components/TopBar';
import Picker from '../components/Picker';
export default class GeralWinners extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            modalOpenend1: false,
            modalOpenend2: false,
            systemColor: 'white',
            user: {},
            currentUrl: "",
            currentSystem: "",
            rewards: [],
            currentReward: {},
            currentHistoric: {},
            historics: [],
            modalOrders: [{cartelas: []}],
            isLoading : false,
            sorteioStatus: true,
            selectedHistoric: null,
            selectedValue: null
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            getStatusStore(res).then(res => {
                this.setState({sorteioStatus: res.sorteioStatus})
            })
            getHistorics(res).then(res => this.setState({historics : Array.isArray(res)? res : []}))
            getCurrentRewards(res).then(res => {
                this.setState({rewards : Array.isArray(res)? res : []});
                this.setState({isLoading : false});
            })
        })
    }

    getFullSystemName(){
        return this.state.currentSystem == "cf"?  "CartelaFlash" : this.state.currentSystem == "fd"? "FlashDay" : "Sorteflash"
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        return url;
    }
    
    handleModalHistoric(){
        this.setState({modalOpenend2 : !this.state.modalOpenend2})
    }

    searchIntervalRewards(){
        this.setState({isLoading : true});
        if(this.state.selectedHistoric == null){

            getCurrentRewards(this.state.currentUrl).then(res => {
                this.setState({rewards : Array.isArray(res)? res : []});
                this.setState({isLoading : false});
            })
        }
        else{
            getHistoricsRewardsInterval(this.state.currentUrl,  this.state.selectedHistoric.dataInicial, this.state.selectedHistoric.dataFinal).then(res => {this.setState({rewards : res}) ;         this.setState({isLoading : false});});
        }
    }

    formatDate(data) {
        return moment(data).add(0, 'days').format('DD-MM-YYYY')
    }

    compararDatas(a, b) {
        var A = new Date(a.dataInicial)
        var B = new Date(b.dataInicial)
        if (A < B) {
          return 1;
        }
        if (A > B) {
          return -1;
        }
        return 0
    }

    render() {

        
        if(this.state.sorteioStatus){
            return (
                <View style={this.state.styles.container}>
                    <View  style={{...this.state.styles.mt32, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{...this.state.styles.bgDark, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.cLight, ...this.state.styles.bold}}>
                                Indisponivel no momento!
                            </Text>
                        </View>
                    </View>
                </View>
            );
        }
        else{
        return (
            <TopBar navigation={this.props.navigation}  isLoading={this.state.isLoading} >
            <View style={{width: "100%", ...this.state.styles.justifyCenter,  ...this.state.styles.alignCenter}}>
                <View style={this.state.styles.container}>
                        <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                            <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Premios do sorteio passado</Text>
                        </View>
                        {this.state.modalOpenend2?      
                        <ModalReward currentReward={this.state.currentReward} action={this.handleModalHistoric.bind(this)}></ModalReward>  
                        : <Text></Text>}



                        <View style={{...this.state.styles.row, ...this.state.styles.alignCenter, justifyContent: 'space-around', ...this.state.styles.mt8}}>
                            <View style={{width: '40%' , borderWidth: 1, borderColor: 'gray', borderRadius: '4px'  }}>
                            {this.state.historics.length > 0? 
                            <Picker 
                            placeHolder={"Periodo do sorteio"}
                            onValueChange={itemValue => {this.setState({selectedValue : itemValue, selectedHistoric : JSON.parse(itemValue)});}}
                            arrayLabelValue={[
                                {label : `${this.formatDate(this.state.historics[0].dataFinal)}`, value : JSON.stringify(this.state.historics[0])},
                                {label : `${this.formatDate(this.state.historics[1].dataFinal)}`, value : JSON.stringify(this.state.historics[1])},
                                {label : `${this.formatDate(this.state.historics[2].dataFinal)}`, value : JSON.stringify(this.state.historics[2])},
                            ]}></Picker>
                            : <Text></Text>}
                            </View>

                            <View style={{width: '18%'}}>
                                <TouchableOpacity onPress={() => this.searchIntervalRewards()}>
                                    <Image source={lupa_icon} style={{width : 22, height: 22, marginLeft: 12}} />
                                </TouchableOpacity>
                            </View>
                        </View>




                        <ScrollView style={{padding: 8}}>
                            {this.state.rewards.map((reward,index) => 
                            <View key={"v1"+index} style={{...this.state.styles.mt8, width: '100%',  height: 96, ...this.state.styles.myShadow, ...this.state.styles.row, ...this.state.styles.alignCenter}}>
                                <View key={"v2"+index} style={{width: '80%',  paddingLeft: 12, height: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter}}>
                                    <Text key={"tx"+index} style={{...this.state.styles.fs10, ...this.state.styles.bold}}>{reward.tipo} - Cartela: {reward.cartelaSorteada.codigo} - {reward.ganhadorId.nome} - {reward.ganhadorId.cidade}-{reward.ganhadorId.estado}</Text>
                                </View>
                                <View key={"v3"+index} style={{width: '20%',  paddingLeft: 12, height: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter}}>
                                    <TouchableOpacity key={"to"+index} onPress={() => {this.setState({currentReward: reward, modalOpenend2 : true}); }}>
                                        <Image key={"img"+index} source={note_icon} style={{width : 22, height: 22, marginLeft: 12}} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            )}
                        </ScrollView>
                    </View>
                </View>
                </TopBar>
            )
                    }
    }
}
