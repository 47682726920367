import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, Text, Alert, Image, Linking} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import home_icon from '../assets/images/icons/house-solid-dark.png';
import { singinClient, getSAC } from '../services/ApiServices';
import { getData, setData } from '../services/StorageService';
import cf_logo from '../assets/images/logos/cf_logo.png';
import sf_logo from '../assets/images/logos/sf_logo.png';
export default class ChooseProfile extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: ''},
            styles : styles,
            currentUrl: "",
            currentSystem: "",
            email: "",
            password: "redeflash2024",
            image: cf_logo,
            sac: '99999999999'
        }
    }

    
    componentDidMount(){
        getSAC().then(res => {this.setState({sac: res})})
        getData("current_system").then(res => {

            if (res == "cf"){
                this.setState({image: cf_logo});
            }
            else{
                this.setState({image: sf_logo});
            }
        })
        getData("current_url").then(res => this.setState({currentUrl: res}));
        getData("current_system").then(res => {
            this.setState({currentSystem: res});
            getData(res+"_system_auth").then(res => {
                if(res == 'true' || res == true)        this.setState({redirect : {status: true, value: 'Store'}});
            })
            getData(res+"_system_seller_auth").then(res => {
                if(res == 'true' || res == true)        this.setState({redirect : {status: true, value: 'StoreSeller'}});
            })
        });
    }

    async openURL(url){
        const supported = await Linking.canOpenURL(url);
        if (supported) {
          await Linking.openURL(url);
        } else {
          Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    submitForm(){
        if(this.state.email.length < 8 || !this.state.email.toLowerCase().includes("@icloud.com") && !this.state.email.toLowerCase().includes("@gmail.com") && !this.state.email.toLowerCase().includes("@hotmail.com") && !this.state.email.toLowerCase().includes("@outlook.com")){
            alert("E-mail inválido!");
            Alert.alert("E-mail inválido!");
        }
        else if(this.state.password.length < 6){
            alert("Senha inválida!");
            Alert.alert("Senha inválida!");
        }
        else{
            singinClient(this.state.currentUrl, {senha: this.state.password, email: this.state.email}).then(res => {
                alert(res.msg);
                Alert.alert(res.msg);
                setData(`${this.state.currentSystem}_user`, JSON.stringify(res.result));
                this.setState({redirect : {status: true, value: res.redirect}});
                if(res.redirect == "Store")setData(this.state.currentSystem+"_system_auth", 'true');
            })
        }
        
    }

    render() {
        return (
            <View style={this.state.styles.viewPort}>
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
            <View style={this.state.styles.container}>
            <View style={{height:40, ...this.state.styles.row, ...this.state.styles.bgLight, ...this.state.styles.alignCenter}}>
                <TouchableOpacity onPress={() =>         this.setState({redirect : {status: true, value: ''}})}><Image source={home_icon} style={{width : 22, height: 22, marginLeft: 12}} /></TouchableOpacity> 
            </View>
            <View style={{...this.state.styles.container, padding : 32, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <View style={{width: '100%', ...this.state.styles.mt32, ...this.state.styles.alignCenter}}>
                    <Image style={{width: 320, height: 64}} source={this.state.image}/>
                </View>
                <View style={{width: '100%', ...this.state.styles.mt32}}>
                    <ButtonComponent disabled={false} action={() => {        this.setState({redirect : {status: true, value: 'LoginScreen'}});}} variant="primary_filled"> Clientes </ButtonComponent>
                </View>
                <View style={{width: '100%', ...this.state.styles.mt32}}>
                    <ButtonComponent disabled={false} action={() => {        this.setState({redirect : {status: true, value: 'LoginSellerScreen'}});}} variant="danger_filled"> Colaboradores </ButtonComponent> 
                </View>
                <TouchableOpacity onPress={() => this.openURL('https://api.whatsapp.com/send?phone=55'+this.state.sac+'&text=Preciso de ajuda')}><Text style={{...this.state.styles.fs14,...this.state.styles.bold , ...this.state.styles.cSuccess, ...this.state.styles.mt8}}>Duvidas ?</Text></TouchableOpacity>

            </View>
            </View>
            </View>)
    }
}
