import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, TextInput, Text, Alert, Image} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import home_icon from '../assets/images/icons/house-solid-dark.png';
import { singinSeller } from '../services/ApiServices';
import { getData, setData } from '../services/StorageService';
export default class LoginSellerScreen extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            currentUrl: "",
            currentSystem: "",
            phone: "",
            email: "",
            password: "",
        }
    }

    
    componentDidMount(){
        getData("current_url").then(res => this.setState({currentUrl: res}));
        getData("current_system").then(res => {
            this.setState({currentSystem: res});
            getData(res+"_system_auth").then(res => {
                if(res == 'true')this.setState({redirect : {status: true, value: 'Store'}})
            })
        });
    }


    submitForm(){
        this.state.email = this.state.email.replace(/\s/g,'');
        if(this.state.email.length < 5 || !this.state.email.includes("@") || !this.state.email.includes(".com")){
            alert("Email inválido!");
            Alert.alert("Email inválido!");
        }
        else{
            singinSeller(this.state.currentUrl, {senha: this.state.password, email: this.state.email}).then(res => {
                alert(res.msg);
                Alert.alert(res.msg);
                setData(`${this.state.currentSystem}_user`, JSON.stringify(res.result));
                this.setState({redirect : {status: true, value: res.redirect}})
                if(res.redirect == "StoreSeller")setData(this.state.currentSystem+"_system_seller_auth", 'true');
            })
        }
        
    }

    render() {
        return (
            <View style={this.state.styles.viewPort}>
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
            <View style={this.state.styles.container}>
            <View style={{height:40, ...this.state.styles.row, ...this.state.styles.bgLight, ...this.state.styles.alignCenter}}>
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: ''}})}><Image source={home_icon} style={{width : 22, height: 22, marginLeft: 12}} /></TouchableOpacity> 
            </View>
            <View style={{...this.state.styles.container, padding : 32, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <Text style={{...this.state.styles.fs22, ...this.state.styles.bold}}>Login Colaborador</Text>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} placeholder='Email'
                        onChangeText={(value) => {this.setState({email : value.replace(/\s/g, '')})}}
                        value={this.state.email}
                        keyboardType="default"
                ></TextInput>

                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} placeholder='Senha'
                        onChangeText={(value) => {this.setState({password : value})}}
                        value={this.state.password}
                        textContentType="password"
                        secureTextEntry={true}
                        keyboardType="default"
                ></TextInput>
                <View style={{width: '100%', ...this.state.styles.mt8}}>
                    <ButtonComponent disabled={false} action={this.submitForm.bind(this)} variant="primary_filled"> Entrar </ButtonComponent>
                </View>
                <View style={{width: '100%', ...this.state.styles.mt8}}>
                    {/* <View style={{width: '100%', ...this.state.styles.mt64,...this.state.styles.row,...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <TouchableOpacity onPressIn={() => this.setState({redirect : {status: true, value: 'ResetPasswordScreen'}})}><Text style={{...this.state.styles.fs14,...this.state.styles.bold , ...this.state.styles.cDanger, ...this.state.styles.mt8}}>Esqueceu a senha?</Text></TouchableOpacity>
                    </View> */}
                    <View style={{width: '100%', ...this.state.styles.mt16,...this.state.styles.row,...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                        <TouchableOpacity onPressIn={() => this.setState({redirect : {status: true, value: 'SellerSingUpScreen'}})}><Text style={{...this.state.styles.cPrimary, ...this.state.styles.bold}}>Cadastro de vendedor</Text></TouchableOpacity>
                    </View>
                </View>
            </View>
            </View>
            </View>
            )
    }
}
