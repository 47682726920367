import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import {Text, View} from 'react-native';
import NavBarSeller from '../components/NavBarSeller';
import { getSAC } from '../services/ApiServices';
export default class RegulationSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            modalOpenend: false,
            isLoading: false,
               sac: '99999999999'
        }
    }

    componentDidMount(){

        getSAC().then(res => {this.setState({sac: res})})
    }

    handleModalOrder(){
        this.setState({modalOpenend : !this.state.modalOpenend})
    }

    render() {
        return (
            <NavBarSeller navigation={this.props.navigation} >
                <View style={{
                    padding: 24, 
                    width: "100%", 
                    height: "100%", 
                    display: "flex", 
                    flexDirection: "column",   
                    textAlign: "justify"}}>


                
                <Text style={{
                    padding: 24, 
                    width: "100%",   
                    textAlign: "justify"}}>
                { "Sorte Flash é um instrumento de ação social, que busca na sociedade o apoio, através da aquisição de bilhetes, para custear sua missão filantrópica."+
                    "\n \n(1)O sistema Sorte Flash não se responsabiliza por cartelas avuslas que não foram validadas no sistema Sorte Flash."+
                    "\n \n(2) Irá participar do sorteio apenas cartelas validadas no sistema Sorte Flash, mediante pagamento antecipado."+
                    "\n \n(3) O sorteio é transmitido ao vivo em nossa página no YouTube Sorte Flash."+
                    "\n \n(4) O globo contém de uma até 75 bolas...."+
                    "\n \n(5) Cartelas avulsas ou digitais contém 15 dezenas."+
                    "\n \n(6) O sorteio é contínuo, ou seja, as bolas não volta para o globo após cada batida."+
                    "\n \n(7) Uma cartela tem probabilidade de ganhar várias vezes no mesmo sorteio."+
                    "\n \nQuadra, o primeiro a marcar quatro dezenas em uma das três linhas horizontais ganha o prêmio."+
                    "\n \nQuina, o primeiro a marcar cinco dezenas em uma das três linhas horizontais ganha o prêmio."+
                    "\n \nCruzadinha, o primeiro a marcar sete dezenas, formando uma cruz no centro do bilhete ganha o prêmio."+
                    "\n \nCheia, o primeiro a marcar quinze dezenas ganha o prêmio."+
                    "\n \nAcumuladão Cheia (-40bolas) ganha quem acertar na cheia com menos 40 bolas, se ninguém acertar, soma 200,00 "+
                    "\n \nem cada extração até completar máxima de 10 mil, os 10 mil ficará acumulado até sair."+
                    "\n \n(8) Em caso de dois ou mais ganhadores o prêmio será dividido em partes iguais."+
                    "\n \nFina da cheia, quem marcar quatorze dezenas, ou seja, ficar só por um número na cartela cheia ganha o prêmio."+
                    "\n \n(8) Em caso de dois ou mais  ganhadores o prêmio será dividido em partes iguais."+
                    "\n \n(9) Os prêmios serão pago pelos responsáveis em até sete dias úteis, mediante apresentação da cartela premiada, contato do responsável está em sua cartela."+
                    "\n \n(10) Em caso de dúvidas entre em contato no WhatsApp da central dúvidas Sorte Flash ("+this.state.sac.substr(0,2)+') '+this.state.sac.substr(2,6)+'-'+this.state.sac.substr(6)+'.'+
                    "\n \n(11) Sorte flash é proibido para menores de 18 anos...."
                }
                     </Text>
                    <Text style={{fontWeight: "bold"}}> 
                        Lembre-se,  aqui sua sorte continua...
                    </Text>
                     </View>
            </NavBarSeller>
            )
    }
}
