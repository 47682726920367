import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,ScrollView, Image, Text, Alert, Linking, TouchableOpacity} from 'react-native';
import NavBarSeller from '../components/NavBarSeller';
import layers_icon from '../assets/images/icons/layer-group-solid.png';
import whatsapp_icon from '../assets/images/icons/whatsapp.png';
import printer_icon from '../assets/images/icons/print-solid.png';
import { getSellerOrders, getDesconto } from '../services/ApiServices';
import { getData } from '../services/StorageService';
export default class OrdersSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            orders: [],
            report: {cartelas: 0, apurado: 0, comissao: 0, banca: 0},
            isLoading : false,
            desconto: 0
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            getSellerOrders(res.url, res.user._id).then(res => {
                this.setState({orders : Array.isArray(res)? res.reverse() : []})
                this.generateReport(Array.isArray(res)? res : []);
                this.setState({isLoading : false});
            })
            
        })

        


    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        getDesconto(url).then(res => this.setState({desconto: res}));
        return {url, user : JSON.parse(user)};
    }

    generateReport(orders){
        if(orders.length > 0){
                if(this.state.user.vendedorApp){
                    this.state.user.comissao = this.state.desconto;
                }
                let cartelas = 0;
                orders.map(order => {
                    cartelas += order.cartelas.length;
                })
                this.setState({report : {
                    cartelas : cartelas, 
                    apurado: cartelas * parseFloat(orders[0].cartelas[0].valor).toFixed(2),
                    comissao: cartelas * this.state.user.comissao,
                    banca: (cartelas * parseFloat(orders[0].cartelas[0].valor).toFixed(2)) - (cartelas * this.state.user.comissao)
            }})
        }
    }

    getUrlLink(order){
        let url = this.state.currentUrl.includes("cartelaflash")? "cartelaflash" : this.state.currentUrl.includes("flashday")? "flashday" : "sorteflash";
        return `https://www.${url}.com/imprimir?pedido=${order._id}`
    }

    async openURL(url){
        const supported = await Linking.canOpenURL(url);
        if (supported) {
          await Linking.openURL(url);
        } else {
          Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }


    render() {
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Minhas cartelas</Text>
                    {/* <Image source={layers_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
                </View>
                <View style={{...this.state.styles.col}}>
                    <View style={{height:40, ...this.state.styles.row, width: '100%'}}>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Cartelas</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Apurado</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Comissão</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Banca</Text>
                        </View>
                    </View>
                    <View style={{height:40, ...this.state.styles.row, ...this.state.styles.myShadow, width: '100%'}}>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row, ...this.state.styles.myShadow, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>{this.state.report.cartelas}</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row, ...this.state.styles.myShadow, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>R$: {this.state.report.apurado}</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row, ...this.state.styles.myShadow, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>R$: {this.state.report.comissao}</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row, ...this.state.styles.myShadow, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>R$: {this.state.report.banca}</Text>
                        </View>
                    </View>
                </View>

                <ScrollView>
                    <View style={{...this.state.styles.container, ...this.state.styles.col, ...this.state.styles.mt16 , ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    {this.state.orders.map(order =>
                        <View style={{padding: 12, height: 160, ...this.state.styles.myShadow, width: '90%', ...this.state.styles.row}}>
                            <View style={{height: '100%', width: '80%', ...this.state.styles.col}}>
                                <Text style={{...this.state.styles.mt8,...this.state.styles.bold}}>Nome: {order.clienteId.nome}</Text>
                                <Text style={{...this.state.styles.mt8,...this.state.styles.bold}}>Data: {order.cartelas[0].data} {order.cartelas[0].horario}hrs</Text>
                                <Text style={{...this.state.styles.mt8,...this.state.styles.bold}}>Pedido: {order.codigo}</Text>
                                <Text style={{...this.state.styles.mt8,...this.state.styles.bold}}>Cartelas: {order.cartelas.length}</Text>
                                <Text style={{...this.state.styles.mt8,...this.state.styles.bold}}>Valor: {order.cartelas.length * parseFloat(order.cartelas[0].valor)}</Text>
                            </View> 
                            <View style={{height: '100%', width: '20%', ...this.state.styles.col, justifyContent: 'space-evenly'}}>
                                <TouchableOpacity onPress={() => this.openURL(`https://api.whatsapp.com/send?text=` + this.getUrlLink(order))}>
                                    <Image source={whatsapp_icon} style={{width : 32, height: 32}} />
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.openURL(this.getUrlLink(order))} >
                                    <Image source={printer_icon} style={{width : 32, height: 32}} ></Image>
                                </TouchableOpacity>
                            </View>  
                        </View>
                    )}
                    </View>
                </ScrollView>          
            </NavBarSeller>
            )
    }
}
