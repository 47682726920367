import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,ScrollView,Linking, Text, Alert, TouchableOpacity, Image} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import whatsapp_icon from '../assets/images/icons/whatsapp.png';
import printer_icon from '../assets/images/icons/print-solid.png';
import { getData } from '../services/StorageService';
import NavBarSeller from '../components/NavBarSeller';
import { setReceiveOrder, getCreditos } from '../services/ApiServices';
export default class PaymentSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: ''},
            styles : styles,
            modalOpenend: false,
            systemColor: 'white',
            user: "",
            currentUrl: "",
            currentUrl2: "",
            currentSystem: "",
            isLoading : false,
            isReceived: false,
            creditos: 0,
            params: null
        }
    }

    componentDidMount(){
        getData("props").then(res => {
            this.setState({params : JSON.parse(res), isReceived: JSON.parse(res).order.recebido})
        })
        this.initializeDataState();
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            this.setState({isLoading : false});
            if(this.state.params)
                this.setState({currentUrl2: `https://www.${res.split('.')[1]}.com/imprimir?pedido=${this.state.params.order._id}`})
        })
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});

        
        getCreditos(url, JSON.parse(user)._id).then(res => {
            this.setState({creditos: res});
        })
        return url;
    }

    getFullSystemName(){
        return this.state.currentSystem == "cf"?  "CartelaFlash" : this.state.currentSystem == "fd"? "FlashDay" : "Sorteflash"
    }

    handleModalOrder(){
        this.setState({modalOpenend : !this.state.modalOpenend})
    }

    openURL = async (url) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
          await Linking.openURL(url);
        } else {
          Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    validarPedido(){
        this.setState({isLoading : true});
        if(this.state.user.vendedorApp && this.state.creditos < this.state.params.order.cartelas.length){
            this.setState({isLoading : false});
            window.screen? alert("Você não possui crédito suficiente para receber esse pedido.") : console.log("Você não possui crédito suficiente para receber esse pedido.");
            Alert.alert("Você não possui crédito suficiente para receber esse pedido.");
        }
        else{
        setReceiveOrder(this.state.currentUrl,this.state.params.order._id, this.state.user._id).then(() => {
            this.setState({isLoading : false});
            window.screen? alert("Validado com sucesso!") : console.log("Validado com sucesso!");
            Alert.alert("Validado com sucesso!");
            this.state.params.order.recebido = true;
            this.setState({isReceived : true});
            this.setState({redirect : {status: true, value: 'OrdersSeller'}})

        })
        if(this.state.user.vendedorApp)
            getCreditos(this.state.currentUrl, this.state.user._id).then(res => {
                this.setState({creditos: res});
                if(res <= 0)
                    this.setState({redirect : {status: true, value: 'StoreSeller'}})
            })
        }
    }

    getUrlLink(){
        let url = this.state.currentUrl.includes("cartelaflash")? "cartelaflash" : this.state.currentUrl.includes("flashday")? "flashday" : "sorteflash";
        this.openURL(`https://api.whatsapp.com/send?text=https://www.${url}.com/imprimir?pedido=${this.state.params.order._id}`)
    }

    render() {
        if(this.state.params == null){}
        else{
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight, marginLeft: 12}}>Detalhes do pedido</Text>
                </View>
                <View style={{width: '100%', paddingLeft: 8, paddingRight: 8, ...this.state.styles.col}}>
                    <ScrollView>
                        <View style={{...this.state.styles.mt8, width: '100%',  height: 128, ...this.state.styles.myShadow, ...this.state.styles.col}}>
                            <Text style={{marginLeft: 8,...this.state.styles.mt8,...this.state.styles.bold}}>Cliente: {this.state.params.order.clienteId.nome}</Text>
                            {this.state.params.order.cartelas.map(card => 
                                    <Text style={{marginLeft: 64,...this.state.styles.mt8,...this.state.styles.bold}}>Cartelas {card.codigo} ............ R${card.valor}</Text>
                                )}
                            <Text style={{marginLeft: 180,...this.state.styles.mt8,...this.state.styles.bold}}>Total: R${this.state.params.order.cartelas.length * parseFloat(this.state.params.order.cartelas[0].valor).toFixed(2)}</Text>    
                        </View>
                    </ScrollView>
                    {this.state.isReceived? 
                    <View style={{width: '100%', ...this.state.styles.mt32, height: 100, ...this.state.styles.row, ...this.state.styles.alignCenter, justifyContent: 'space-evenly'}}>
                        <TouchableOpacity  onPress={() => this.openURL(`https://api.whatsapp.com/send?text=` + this.state.currentUrl2)} ><Image  style={{width : 32, height: 32}} source={whatsapp_icon}></Image></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.openURL(this.state.currentUrl2)}><Image  style={{width : 32, height: 32}} source={printer_icon}></Image></TouchableOpacity>
                    </View>
                    : <Text></Text>}  
                    {!this.state.isReceived? 
                        <View style={{width: '100%', ...this.state.styles.mt32}}>
                            <ButtonComponent disabled={false} action={() => this.validarPedido()} variant="success_filled" >Validar pedido</ButtonComponent>
                        </View> 
                    : <Text></Text>}     
                </View>

            </NavBarSeller>
            )
    }
}
}
