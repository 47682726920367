import * as React from 'react';
import {NavigateParams} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, Image, Text, Alert, Linking} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import NavBar from '../components/NavBar';
import Card from '../components/Card';
import cart_icon from '../assets/images/icons/cart-shopping-solid.png';
import pix_icon from '../assets/images/icons/pix.png';
import whatsapp_icon from '../assets/images/icons/whatsapp.png';
import trash_icon from '../assets/images/icons/trash-can-solid.png';
import pendente_icon from '../assets/images/icons/pendente-solid.png';
import { getData, removeData} from '../services/StorageService';
import { getNewCard, getStatusStore, getUsuario, getVendaBloqueada, setNewOrder, setPaymentPix, setReturnCard, updateOrderQRCode } from '../services/ApiServices';
export default class Store extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/', params: null},
            styles : styles,
            user: {},
            currentUrl: "",
            currentSystem: "",
            systemColor: 'white',
            currentCard: {numeros: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], codigo: "00000-0", data: "0000-00-00", valor: "0.00", horario: "00:00"},
            cartList: [],
            isLoading: false,
            statusStore : false,
            sorteioStatus: false,
            vendaBloquada : false,
            tutor: null,
            bloqVendas: false
        }

    }

    componentDidMount(){
        getData("current_system_color").then(res => this.setState({systemColor : res}))

        getData("current_url").then(res => {
            this.setState({currentUrl: res})
            getNewCard(res).then(res => res? this.setState({currentCard : res.data? res : this.state.currentCard}) : console.log(''));
            getStatusStore(res).then(res => this.setState({statusStore : res.enableStore, sorteioStatus: res.sorteioStatus}))
            getVendaBloqueada(res).then(res => this.setState({vendaBloquada :  res}))
        });
        getData("current_system").then(res => {
            this.setState({currentSystem: res});
            getData(res+"_user").then(res => {
                this.setState({user : JSON.parse(res)});
            });
        });

        getData("current_url").then(url => {
            getData("current_system").then(sys => {
                getData(sys+"_user").then(user => {
                    getUsuario(url, JSON.parse(user)._id).then(res => {
                        
                        if(res.status == false){
                            this.logout(sys)
                        }
                    })
                });
            });
        });

    }

    logout(sys){
        
        removeData(sys + "_system_auth");
        removeData(sys + "_user");
        removeData("current_system");
        removeData("current_url");
        removeData("current_system_color");
        this.setState({redirect : {status: true, value: ''}});
        window.location.reload();
        
      }
    
    getFullSystemName(){
        return this.state.currentSystem == "cf"?  "CartelaFlash" : this.state.currentSystem == "fd"? "FlashDay" : "Sorteflash"
    }

    nextCard(){
        this.setState({isLoading : true});
        if(this.state.currentCard.codigo !== "00000-0" && !JSON.stringify(this.state.cartList).includes(JSON.stringify(this.state.currentCard)) )
            setReturnCard(this.state.currentUrl, this.state.currentCard);
        getNewCard(this.state.currentUrl).then(res => {
            res? this.setState({currentCard : res.data? res : this.state.currentCard}) : console.log('')
            this.setState({isLoading : false});
        });
    }
    returnCard(card, index){
        this.state.cartList.splice(index,1);
        this.setState({cartList: this.state.cartList});
        setReturnCard(this.state.currentUrl, card).then(res => console.log(res));
    }

    addCardToCart(){
        if(this.state.currentCard.codigo !== "00000-0"  && !this.state.cartList.some(card => this.state.currentCard.codigo == card.codigo)){
            this.state.cartList.push(this.state.currentCard);
            this.setState({cartList: this.state.cartList});
        }
        this.nextCard();
    }

    submitOrder(){
        this.setState({bloqVendas : true})
        this.setState({isLoading : true});
        let isIndicacao = this.state.user.tutorId == "5e5c5ca4cc7aa938f0b47ff8";
        setNewOrder(this.state.currentUrl, {
            cartelas: this.state.cartList,
            cliente: this.state.user,
            data: new Date(),
            isIndicacao: isIndicacao,
            isPixPayment: false,
            isPanfleto: false

        }).then(res => {
            this.setState({cartList: []});
            this.setState({isLoading : false});
            this.openURL(`https://api.whatsapp.com/send?text=${this.getFullSystemName()}: Pedido: ` + res.codigo + ' Valor: R$:' + res.cartelas.length * parseFloat(res.cartelas[0].valor).toFixed(2) + ' Cliente: ' + res.clienteId.nome)
            this.setState({redirect : {status: true, value: 'Store'}})
        
        })

    }

    openURL = async (url) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
          await Linking.openURL(url);
        } else {
          Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    submitPixOrder(){
        this.setState({bloqVendas : true})
        this.setState({isLoading : true});
        let isIndicacao = this.state.user.tutorId == "5e5c5ca4cc7aa938f0b47ff8";
        setNewOrder(this.state.currentUrl, {
            cartelas: this.state.cartList,
            cliente: this.state.user,
            data: new Date(),
            isIndicacao: isIndicacao,
            isPixPayment: true,
            isPanfleto: false
        }).then(res => {
            this.setState({cartList: []});
            this.setState({isLoading : false});
            let order = res;
            order.cambistaId = order.cambistaId._id;
            setPaymentPix(this.state.currentUrl, order, this.state.user.email).then(res => {

                order.codigoCopiaCola = res.body.point_of_interaction.transaction_data.qr_code;
                updateOrderQRCode(this.state.currentUrl, order._id, res.body.point_of_interaction.transaction_data.qr_code )
                this.setState({redirect : {status: true, value: 'PixPayment', params: {order: order}}})
            })
        })
    }


    render() {
        if(!this.state.user.status || this.state.statusStore || this.state.sorteioStatus || this.state.vendaBloquada ){
            return (
                <NavBar navigation={this.props.navigation}  isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                    <View  style={{...this.state.styles.mt32, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{...this.state.styles.bgDark, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.cLight, ...this.state.styles.bold}}>
                                Temporariamente indisponivel!
                            </Text>
                        </View>
                    </View>
                </NavBar>
            )
        }
        else{
        return (
            <NavBar navigation={this.props.navigation}  isLoading={this.state.isLoading} >
            {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={this.state.redirect.params}></NavigateParams> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Escolher cartelas</Text>
                    {/* <Image source={cart_icon} style={{width : 22, height: 22, marginLeft: 12}} /> */}
                </View>

                <Card onArrowPress={this.nextCard.bind(this)} currentCard={this.state.currentCard}></Card>
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><ButtonComponent disabled={false} action={this.addCardToCart.bind(this)} variant="primary_filled" icon={cart_icon}>Carrinho</ButtonComponent></View>
                {this.state.cartList.length > 0? <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt16, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}><Text style={{...this.state.styles.fs18, ...this.state.styles.bold, ...this.state.styles.cDark}}>{`Cartelas: ${this.state.cartList.length} - Valor : R$${this.state.cartList.length * parseFloat(this.state.cartList[0].valor).toFixed(2)}`}</Text></View> : <Text></Text>}
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt16, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    {this.state.cartList.map((card,index) => 
                    <View style={{...this.state.styles.mt8,
                        ...this.state.styles.myShadow, 
                        height: 40, width: '100%', 
                        ...this.state.styles.row, 
                        justifyContent: 'space-around', 
                        ...this.state.styles.alignCenter}}>
                        <Text style={{...this.state.styles.fs10, ...this.state.styles.bold, ...this.state.styles.cDark}}>{`R$${card.valor} - ${card.data.split('-')[2]+'-'+card.data.split('-')[1]+'-'+card.data.split('-')[0]} - Cartela: ${card.codigo}`}</Text>
                            <TouchableOpacity onPress={() => {this.returnCard(card,index)}}><Image source={trash_icon} style={{width : 22, height: 22}}></Image></TouchableOpacity>
                        </View>
                    )}
                </View>
                {this.state.cartList.length > 0? <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt64}}><ButtonComponent disabled={this.state.bloqVendas} action={this.submitOrder.bind(this)} variant="primary_filled" icon={whatsapp_icon}>Validar com vendedor</ButtonComponent></View> : <Text></Text>}
                {this.state.cartList.length > 0? <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><ButtonComponent disabled={this.state.bloqVendas} action={() => this.submitPixOrder()} variant="primary_filled" icon={pix_icon}>Validar na central</ButtonComponent></View> : <Text></Text>}

            
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}>
                    <View style={{width: '100%' }}>
                            <ButtonComponent disabled={false} action={() => {clearInterval(this.state.timeOut); this.setState({redirect : {status: true, value: 'PendingPayment'}})}} variant="warning_filled" icon={pendente_icon}>Conferir pendentes</ButtonComponent>
                    </View>
                    <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><Text style={{...this.state.styles.fs14,...this.state.styles.bold , ...this.state.styles.cDanger, ...this.state.styles.mt8}}>Após confirmar o pagamento confira em 'Minhas cartelas'</Text></View>
                </View>
            </NavBar>
            )
        }
    }
}
