import * as React from 'react';
import {Navigate, NavigateParams} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,ScrollView, Image, Text, TextInput} from 'react-native';
import Picker from '../components/Picker';
import pix_icon from '../assets/images/icons/pix.png';
import ButtonComponent from '../components/ButtonComponent';
import {  checkCartelaDaSerie, checkCartelaDoPedido, getCartelaDaSerie, getSerie, getStatusStore, getUsuario, getUsuarioByTelefone, getValorCartela, getVendaBloqueada, setNewOrder, setNewOrderQRCode, setPaymentPix, singupPasswordClient, singupPasswordClientTelefone, updateCartelaDoPedido, updateOrderQRCode } from '../services/ApiServices';
import { getData , setData} from '../services/StorageService';
import cfLogo from '../assets/images/logos/cf_logo.png';
import fdLogo from '../assets/images/logos/fd_logo.png';
import sfLogo from '../assets/images/logos/sf_logo.png';
import { TouchableOpacity } from 'react-native';
import Loading from '../components/Loading';
import CardTamplate from '../components/CardTamplate';
import TopBar from '../components/TopBar';

export default class ClientValidateOrder extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            currentLogo: sfLogo,
            styles : styles,
            phone: "",
            nome: "",
            city: "",
            UF: "Escolher estado",
            cartelaCodigo: "00000-0",
            serieId: null,
            cambista: null,
            isLoading: false,
            statusStore : false,
            sorteioStatus: false,
            vendaBloquada : false,
            bloqVendas: false,
            user: null,
            currentUrl: null,
            systemColor: null,
            currentSystem: null,
            hasUser: false,
            saiuDaSerie: false,
            valorCartela: 0,
            cartela: {codigo: "00000-0", valor: "00,00", numeros: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], data: "00/00/00", horario: "00:00"}
        }
    }

    componentDidMount(){

        getData("current_cartela_qrcode_data").then(res => {
            var qrcodeData = JSON.parse(res);
            if(qrcodeData.currentSystem == "sf"){
                this.setState({currentLogo: sfLogo})
                qrcodeData.currentUrl = "https://www.sorteflash.com:5000/api/v1/app",
                qrcodeData.systemColor = "#1ca861"

            }
            else {
                this.setState({currentLogo: cfLogo});
                qrcodeData.currentUrl = "https://www.cartelaflash.com:5000/api/v1/app",
                qrcodeData.systemColor = "#701ca8"
            }
            getSerie(qrcodeData.currentUrl, qrcodeData.serieId).then(serie => {
                getUsuario(qrcodeData.currentUrl, serie.cambistaId).then(cambista => {
                    this.setState({ 
                        currentUrl: qrcodeData.currentUrl,
                        systemColor: qrcodeData.systemColor,
                        currentSystem: qrcodeData.currentSystem,
                        cartelaCodigo: qrcodeData.cartelaCodigo,
                        serieId: qrcodeData.serieId,
                        cambista: cambista})
                })

                serie.cartelas.map(cartela => {

                    if(qrcodeData.cartelaCodigo == cartela.codigo)
                        this.setState({cartela: cartela})
                })
            })

            getStatusStore(qrcodeData.currentUrl).then(res => this.setState({statusStore : res.enableStore, sorteioStatus: res.sorteioStatus}))
            getVendaBloqueada(qrcodeData.currentUrl).then(res => this.setState({vendaBloquada :  res}));
            
       
            getData(qrcodeData.currentSystem+"_user").then(res => {
                if(res != null){
                    //this.setState({hasUser: true, user : JSON.parse(res), phone: JSON.parse(res).telefone, nome: JSON.parse(res).nome, city: JSON.parse(res).cidade, UF: JSON.parse(res).estado});                 
                    this.setState({hasUser: false})
                }
                else{
                    this.setState({hasUser: false})
                }
            });

            checkCartelaDaSerie(qrcodeData.currentUrl, qrcodeData.cartelaCodigo).then(res1 => {
                if(res1 == false){
                    this.setState({saiuDaSerie: true})
                }
                checkCartelaDoPedido(qrcodeData.currentUrl, qrcodeData.cartelaCodigo).then(res2=> {
                    if(res1 == false && res2 == true){
                        this.setState({saiuDaSerie: true})
                    }
                if(res1 == false && res2 == false){
                    alert("Essa cartela já foi validada!");
                    this.setState({redirect : {status: true, value: 'Orders', params: {}}})
                }
            });
            });
            getValorCartela(qrcodeData.currentUrl).then(res => this.setState({valorCartela: res}))
            setData("current_system_color", qrcodeData.systemColor);
            setData("current_system",  qrcodeData.currentSystem);
            setData("current_url", qrcodeData.currentUrl);


        })

    }


    onPhoneBlur(e){
        getUsuarioByTelefone(this.state.currentUrl, this.state.phone).then(res => {
            if(res?.nome){
                this.setState({user: res, hasUser: true, phone: res.telefone, nome: res.nome, city: res.cidade, UF: res.estado});
            }
        })
    }

    setPhone(value){
        if(value.length > this.state.phone.length){
            let subMask = "?? ????? ????";
            value.split('').map((character,index) => {
                if(character != ' ' && !isNaN(character)){
                    subMask = subMask.replace('?', character);
                    if(index == value.length - 1){
                        this.setState({phone : subMask.split('?')[0]});
                    }
                }

            });
        }
        else{
            this.setState({phone : value})
        }
    }

    submitPixOrder(){

        this.setState({isLoading : true});
        if(this.state.hasUser == false){
            this.cadastrarUsuario();
        }
        else{
            this.setState({bloqVendas : true})

            if(this.state.saiuDaSerie){
                this.state.user.tutorId = this.state.cambista;
                updateCartelaDoPedido(this.state.currentUrl, this.state.user, this.state.cartelaCodigo).then(pedido => {
                        this.setState({isLoading : false});
                        let order = pedido;
                        setPaymentPix(this.state.currentUrl, order, this.state.user.email).then(res => {
                            order.codigoCopiaCola = res.body.point_of_interaction.transaction_data.qr_code;
                            updateOrderQRCode(this.state.currentUrl, order._id, res.body.point_of_interaction.transaction_data.qr_code )
                            this.setState({isLoading : false, redirect : {status: true, value: 'PixPayment', params: {order: order}}})
                        })
                   
                })      
            }
            else{
                getCartelaDaSerie(this.state.currentUrl, this.state.serieId, this.state.cartelaCodigo).then(cartela => {
                    setNewOrderQRCode(this.state.currentUrl, {
                        cartelas: [cartela],
                        cliente: this.state.user,
                        data: new Date(),
                        cambista: this.state.cambista,
                        isPixPayment: true,
                        isPanfleto: true
                    }).then(res => {
                        this.setState({isLoading : false});
                        let order = res;
                        setPaymentPix(this.state.currentUrl, order, this.state.user.email).then(res => {
                            order.codigoCopiaCola = res.body.point_of_interaction.transaction_data.qr_code;
                            updateOrderQRCode(this.state.currentUrl, order._id, res.body.point_of_interaction.transaction_data.qr_code )
                            this.setState({redirect : {status: true, value: 'PixPayment', params: {order: order}}})
                        })
                    })
                })
        }
        }
    }


    async cadastrarUsuario(){
        if(this.state.nome.length < 6){
            alert("Nome inválido!");
            // Alert.alert("Nome inválido!");
            this.setState({isLoading : false});
        }
        if(this.state.phone.length < 13){
            alert("Telefone inválido!");
            // Alert.alert("Telefone inválido!");
            this.setState({isLoading : false});
        }
        else if(this.state.city.length < 3){
            alert("Cidade inválida!");
            // Alert.alert("Cidade inválida!");
            this.setState({isLoading : false});
        }
        else if(this.state.UF == "Escolher estado" || this.state.UF.length < 2 || this.state.UF.length > 2){
            alert("Estado inválida!");
            // Alert.alert("Estado inválida!");
            this.setState({isLoading : false});
        }
        else{
            try {
                const res = await singupPasswordClientTelefone(this.state.currentUrl, {
                    nome: this.state.nome, 
                    telefone: this.state.phone, 
                    cidade: this.state.city,
                    estado: this.state.UF,
                    email: this.state.nome.replace(/\s/g, '')+"@sorteflash.com",
                    senha : "123456789"
                });
                //alert(res.msg);
                // Alert.alert(res.msg);
                this.setState({user : res.result, hasUser: true});
                setData(`${this.state.currentSystem}_user`, JSON.stringify(res.result));
                setData(this.state.currentSystem+"_system_auth", 'true');
                this.submitPixOrder();
            } catch (error) {
                console.log(error)
            }

        }

    }

    limparCampos(e){
        this.setState({user: null, phone: "", nome: "", city: "", UF: ""})
    }

    
    render() {
        return ( 
            <TopBar>
            <View style={this.state.styles.viewPort}>
            {this.state.isLoading? <Loading></Loading> : <Text></Text>}
            {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={this.state.redirect.params}></NavigateParams> : <Text></Text>}
            <View style={this.state.styles.container}>
                <View style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", textAlign: "center", padding: 12}}>
                    <Image source={this.state.currentLogo}style={{width: 270, height: 64}}></Image>
                </View>

                <View style={{width: "100%", display: "flex", justifyContent: "center", textAlign: "center", padding: 12}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs22, ...this.state.styles.cDark}}>Validar sua cartela</Text>
                </View>
                <View style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <Text  style={{...this.state.styles.fs14, ...this.state.styles.cDark}}>Codigo da cartela: </Text>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cDark}}>{this.state.cartelaCodigo}</Text>
                    {/* <CardTamplate currentCard={this.state.cartela}></CardTamplate> */}
                </View>
                <View style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <Text  style={{...this.state.styles.fs14, ...this.state.styles.cDark}}>Numeros: </Text>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cDark}}>{`${this.state.cartela.numeros.join()}`}</Text>
                    {/* <CardTamplate currentCard={this.state.cartela}></CardTamplate> */}
                </View>
                <View style={{width: "100%", display: "flex", padding: 12, flexDirection: "row"}}>
                    <Text  style={{...this.state.styles.fs14, ...this.state.styles.cDark}}>Valor a paga: R$ {this.state.valorCartela}</Text>
                </View>
                <View style={{width: "100%", display: "flex", padding: 12}}>
                    <TextInput disabled={this.state.user != null} style={{width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} placeholder='WhatsApp'
                            onChangeText={(value) => {this.setPhone(value)}}
                            value={this.state.phone}
                            keyboardType="numeric"
                            maxLength={13}
                            onBlur={this.onPhoneBlur.bind(this)}
                            
                    ></TextInput>
                </View>
                <View style={{width: "100%", display: "flex", padding: 12}}>
                    <TextInput disabled={this.state.user != null} style={{width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                            placeholder='Nome completo'
                            onChangeText={(value) => {this.setState({nome : value})}}
                            value={this.state.nome}
                            keyboardType="default"
                            maxLength={128}
                    ></TextInput>
                </View>

                <View style={{width: "100%", display: "flex", padding: 12}}>
                    <View style={{width: '100%',  height: 'auto' , ...this.state.styles.myShadow}} >
                        <Picker isAutoComplete={true} placeHolder={this.state.UF} onValueChange={(value) => this.setState({UF: value})} arrayLabelValue={[
                            {label : "Acre", value :"AC"},
                            {label : "Alagoas", value :"AL"},
                            {label : "Amapá", value :"AP"},
                            {label:"Amazonas", value:"AM"},
                            {label:"Bahia", value:"BA"},
                            {label:"Ceará",value:"CE"},
                            {label:"Distrito Federal", value:"DF"},
                            {label:"Espírito Santo",value:"ES" },
                            {label:"Goiás" ,value:"GO"},
                            {label:"Maranhão" , value:"MA"},
                            {label:"Mato Grosso" , value:"MT"},
                            {label:"Mato Grosso do Sul",value:"MS"},
                            {label:"Minas Gerais", value:"MG"},
                            {label:"Pará", value:"PA"},
                            {label: "Paraíba" , value:"PB"},
                            {label: "Paraná" , value:"PR"},
                            {label: "Pernambuco" , value:"PE"},
                            {label: "Piauí", value:"PI"},
                            {label: "Rio de Janeiro", value:"RJ"},
                            {label:"Rio Grande do Norte" , value:"RN"},
                            {label: "Rio Grande do Sul", value:"RS"},
                            {label:"Rondônia", value:"RO"},
                            {label:"Roraima", value:"RR"},
                            {label: "Santa Catarina", value:"SC"},
                            {label: "São Paulo", value:"SP"},
                            {label: "Sergipe", value:"SE"},
                            {label:"Tocantins" , value:"TO"}
                        ]}></Picker>
                    </View>
                </View>
                <View style={{width: "100%", display: "flex", padding: 12}}>
                    <TextInput disabled={this.state.user != null} style={{ width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                            placeholder='Cidade'
                            onChangeText={(value) => {this.setState({city : value})}}
                            value={this.state.city}
                            keyboardType="default"
                            maxLength={24}
                    ></TextInput>
                </View>

                <View style={{width: "100%", display: "flex", padding: 12}}>
                    <View style={{width: '100%', paddingLeft: 32, paddingRight: 32}}><ButtonComponent disabled={this.state.bloqVendas} action={() => this.submitPixOrder()} variant="primary_filled" icon={pix_icon}>Pagamento PIX</ButtonComponent></View>
                </View>
                <View style={{width: "100%", display: "flex", padding: 12}}>
                    <View style={{width: '100%', paddingLeft: 32, paddingRight: 32}}><ButtonComponent action={() => this.limparCampos()} variant="warning_filled" >Limpar campos</ButtonComponent></View>
                </View>
        </View>
        </View>
        </TopBar>
        )
    }
}
