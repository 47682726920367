import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, TextInput, Text, Alert,Image} from 'react-native';
import Picker from '../components/Picker';
import ButtonComponent from '../components/ButtonComponent';
import { singupPasswordSeller} from '../services/ApiServices';
import { getData } from '../services/StorageService';
import home_icon from '../assets/images/icons/house-solid-dark.png';
export default class SellerSingUpScreen extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            currentUrl: "",
            currentSystem: "",
            phone: "",
            email: "",
            password: "",
            valPassword: "",
            firstName: "",
            lastName: "",
            city: "",
            UF: "",
        }
    }

    componentDidMount(){
        getData("current_url").then(res => this.setState({currentUrl: res}));
        getData("current_system").then(res => this.setState({currentSystem: res}));
    }

    setPhone(value){
        if(value.length > this.state.phone.length){
            let subMask = "?? ????? ????";
            value.split('').map((character,index) => {
                if(character != ' ' && !isNaN(character)){
                    subMask = subMask.replace('?', character);
                    if(index == value.length - 1){
                        this.setState({phone : subMask.split('?')[0]});
                    }
                }

            });
        }
        else{
            this.setState({phone : value})
        }
    }

    submitForm(){
        this.state.email = this.state.email.replace(/\s/g,'');
        if(this.state.firstName.length < 3){
            alert("Nome inválido!");
            Alert.alert("Nome inválido!");
        }
        else if(this.state.phone.length < 13){
            alert("Telefone inválido!");
            Alert.alert("Telefone inválido!");
        }
        else if(this.state.city.length < 3){
            alert("Cidade inválida!");
            Alert.alert("Cidade inválida!");
        }
        else if(this.state.password > 6 && this.state.password === this.state.valPassword){
            alert("Senha inválida!");
            Alert.alert("Senha inválida!");
        }
        else if(this.state.UF.length < 2 || this.state.UF.length > 2){
            alert("Estado inválida!");
            Alert.alert("Estado inválida!");
        }
        else if(this.state.email.length < 8 || !this.state.email.toLowerCase().includes("@icloud.com") && !this.state.email.toLowerCase().includes("@gmail.com") && !this.state.email.toLowerCase().includes("@hotmail.com") && !this.state.email.toLowerCase().includes("@outlook.com")){
            alert("E-mail inválido!");
            Alert.alert("E-mail inválido!");
        }
        else{
            singupPasswordSeller(this.state.currentUrl, {
                nome: this.state.firstName + " " + this.state.lastName, 
                telefone: this.state.phone, 
                cidade: this.state.city,
                estado: this.state.UF,
                email: this.state.email,
                senha : this.state.password
            }).then(res => {
                alert(res.msg);
                Alert.alert(res.msg);
                this.setState({redirect : {status: true, value: res.redirect}});
            })
        }

    }

    render() {
        return (
            <View style={this.state.styles.viewPort}>
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
            <View style={this.state.styles.container}>
            <View style={{height:40, ...this.state.styles.row, ...this.state.styles.bgLight, ...this.state.styles.alignCenter}}>
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: ''}})}><Image source={home_icon} style={{width : 22, height: 22, marginLeft: 12}} /></TouchableOpacity> 
            </View>
            <View style={{...this.state.styles.container, padding : 32, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <Text style={{...this.state.styles.fs22, ...this.state.styles.bold}}>Cadastre-se</Text>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} placeholder='Whatsapp'
                        onChangeText={(value) => {this.setPhone(value)}}
                        value={this.state.phone}
                        keyboardType="numeric"
                        maxLength={13}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Nome'
                        onChangeText={(value) => {this.setState({firstName : value})}}
                        value={this.state.firstName}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Sobrenome'
                        onChangeText={(value) => {this.setState({lastName : value})}}
                        value={this.state.lastName}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Email'
                        onChangeText={(value) => {this.setState({email : value})}}
                        value={this.state.email}
                        keyboardType="default"
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Cidade'
                        onChangeText={(value) => {this.setState({city : value})}}
                        value={this.state.city}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
                <View style={{...this.state.styles.mt8, width: '100%',  height: 'auto' , ...this.state.styles.myShadow}} >
                    <Picker isAutoComplete={true} placeHolder={"Escolher"} onValueChange={(value) => this.setState({UF: value})} arrayLabelValue={[
                        {label : "Acre", value :"AC"},
                        {label : "Alagoas", value :"AL"},
                        {label : "Amapá", value :"AP"},
                        {label:"Amazonas", value:"AM"},
                        {label:"Bahia", value:"BA"},
                        {label:"Ceará",value:"CE"},
                        {label:"Distrito Federal", value:"DF"},
                        {label:"Espírito Santo",value:"ES" },
                        {label:"Goiás" ,value:"GO"},
                        {label:"Maranhão" , value:"MA"},
                        {label:"Mato Grosso" , value:"MT"},
                        {label:"Mato Grosso do Sul",value:"MS"},
                        {label:"Minas Gerais", value:"MG"},
                        {label:"Pará", value:"PA"},
                        {label: "Paraíba" , value:"PB"},
                        {label: "Paraná" , value:"PR"},
                        {label: "Pernambuco" , value:"PE"},
                        {label: "Piauí", value:"PI"},
                        {label: "Rio de Janeiro", value:"RJ"},
                        {label:"Rio Grande do Norte" , value:"RN"},
                        {label: "Rio Grande do Sul", value:"RS"},
                        {label:"Rondônia", value:"RO"},
                        {label:"Roraima", value:"RR"},
                        {label: "Santa Catarina", value:"SC"},
                        {label: "São Paulo", value:"SP"},
                        {label: "Sergipe", value:"SE"},
                        {label:"Tocantins" , value:"TO"}
                    ]}></Picker>
                </View>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Senha'
                        onChangeText={(value) => {this.setState({password : value})}}
                        value={this.state.password}
                        secureTextEntry={true}
                        textContentType="password"
                        keyboardType="default"
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Confirmar senha'
                        onChangeText={(value) => {this.setState({valPassword : value})}}
                        value={this.state.valPassword}
                        secureTextEntry={true}
                        textContentType="password"
                        keyboardType="default"
                ></TextInput>
                <View style={{width: '100%', ...this.state.styles.mt8}}>
                    <ButtonComponent disabled={false} action={this.submitForm.bind(this)} variant="primary_filled">Confirmar </ButtonComponent>
                </View>
                <TouchableOpacity ><Text style={{...this.state.styles.fs14,...this.state.styles.bold , ...this.state.styles.cDanger, ...this.state.styles.mt32}}>Após o cadastro enviaremos um email para você.</Text></TouchableOpacity>
            </View>
            </View>
            </View>)
    }
}
