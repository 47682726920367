import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,ScrollView, TouchableOpacity, Image, Text} from 'react-native';
import NavBarSeller from '../components/NavBarSeller';
import note_icon from '../assets/images/icons/note-sticky-regular.png';
import { getData } from '../services/StorageService';
import { getCurrentRewards, getHistorics, getHistoricsRewards, getStatusStore } from '../services/ApiServices';
import moment from "moment";
import ModalReward from '../components/ModalReward';
export default class HistoricWinnersSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            modalOpenend1: false,
            modalOpenend2: false,
            systemColor: 'white',
            user: {},
            currentUrl: "",
            currentSystem: "",
            rewards: [],
            currentReward: {},
            currentHistoric: {},
            historics: [],
            modalOrders: [{cartelas: []}],
            isLoading: false,
            sorteioStatus: true
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});

        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            getStatusStore(res).then(res => {
                this.setState({sorteioStatus: res.sorteioStatus})
            })
            getHistorics(res).then(res => this.setState({historics : res}))
            getCurrentRewards(res).then(res => {
                this.setState({rewards : Array.isArray(res)? res : []})
                this.setState({isLoading : false});
            })
        })
    }

    getFullSystemName(){
        return this.state.currentSystem == "cf"?  "CartelaFlash" : this.state.currentSystem == "fd"? "FlashDay" : "Sorteflash"
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        return url;
    }
    
    handleModalHistoric(){
        this.setState({modalOpenend1 : !this.state.modalOpenend1})
    }

    searchIntervalRewards(historic){
        this.setState({isLoading : true});
        getHistoricsRewards(this.state.currentUrl,historic.dataInicial, new Date(historic.dataFinal).setDate(new Date(historic.dataFinal).getDate() + 1).toString()).then(res => {
            this.setState({rewards : res})
            this.setState({isLoading : false});
        });
    }

    formatDate(data) {
        return moment(data).add(0, 'days').format('DD-MM-YYYY')
    }

    compararDatas(a, b) {
        var A = new Date(a.dataInicial)
        var B = new Date(b.dataInicial)
        if (A < B) {
          return 1;
        }
        if (A > B) {
          return -1;
        }
    
        return 0
    }

    render() {

        if(this.state.sorteioStatus){
            return (
                <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
                    <View  style={{...this.state.styles.mt32, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{...this.state.styles.bgDark, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.cLight, ...this.state.styles.bold}}>
                                Indisponivel no momento!
                            </Text>
                        </View>
                    </View>
                </NavBarSeller>
            );
        }
        else{
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                {this.state.modalOpenend2?      
                   <ModalReward currentReward={this.state.currentReward} action={this.handleModalHistoric.bind(this)}></ModalReward>  
                : <Text></Text>}
                <ScrollView style={{padding: 8}}>
                    {this.state.rewards.map((reward,index) => 
                    <View key={"v1"+index} style={{...this.state.styles.mt8, width: '100%',  height: 96, ...this.state.styles.myShadow, ...this.state.styles.row, ...this.state.styles.alignCenter}}>
                        <View key={"v2"+index} style={{width: '80%',  paddingLeft: 12, height: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter}}>
                            <Text key={"tx"+index} style={{...this.state.styles.fs10, ...this.state.styles.bold}}>{reward.tipo} - Cartela: {reward.cartelaSorteada.codigo} - {reward.ganhadorId.nome} - {reward.ganhadorId.cidade}-{reward.ganhadorId.estado}</Text>
                        </View>
                        <View key={"v3"+index} style={{width: '20%',  paddingLeft: 12, height: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter}}>
                            <TouchableOpacity key={"to"+index} onPress={() => {this.setState({modalOpenend2 : true, currentReward: reward}); }}>
                                <Image key={"img"+index} source={note_icon} style={{width : 22, height: 22, marginLeft: 12}} />
                            </TouchableOpacity>
                        </View>
                    </View>
                    )}

                </ScrollView>
            </NavBarSeller>
            )
        }
    }
}
