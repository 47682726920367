import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,Text, ScrollView} from 'react-native';
import NavBar from '../components/NavBar';
import { getData } from '../services/StorageService';
import { getClientPendingOrders, getHistorics, getHistoricsOrders } from '../services/ApiServices';
import moment from "moment";
import ModalOrders from "../components/ModalOrders"
import Picker from '../components/Picker';
import CardOrder from '../components/CardOrder';
import ButtonComponent from '../components/ButtonComponent';
export default class HistoricOrders extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            modalOpenend2: false,
            user: {},
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            orders: [],
            selectedHistoric: null,
            selectedValue: null,
            selectedHistoric: null,
            historics: [],
            modalOrders: [{cartelas: []}],
            isLoading : false,
            currentNumbers: []
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(async (res) => {
            this.state.currentUrl = res;
            // getClientPendingOrders(res, this.state.user._id).then(res => {
            //     this.setState({orders : Array.isArray(res)? res : []})
            //     this.setState({isLoading : false});
            // });
            getHistorics(res).then(res => {
                this.setState({historics : Array.isArray(res)? res : []})
                getHistoricsOrders(this.state.currentUrl, this.state.user._id, res[0].dataInicial, res[0].dataFinal ).then(res => {this.setState({modalOrders: res, isLoading : false});});
            })

        })
    }

    getFullSystemName(){
        return this.state.currentSystem == "cf"?  "CartelaFlash" : this.state.currentSystem == "fd"? "FlashDay" : "Sorteflash"
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        return url;
    }
    
    handleModalHistoric(){
        this.setState({modalOpenend2 : !this.state.modalOpenend2})
    }

    handleModalOrders(){
            this.setState({isLoading : true, orders: []});
            getHistoricsOrders(this.state.currentUrl, this.state.user._id, this.state.selectedHistoric.dataInicial, this.state.selectedHistoric.dataFinal ).then(res => {
                this.setState({orders : Array.isArray(res)? res : [], modalOpenend2: Array.isArray(res)? true : false, isLoading : false})
            });
    }

    formatDate(data) {
        return moment(data).add(0, 'days').format('DD-MM-YYYY')
    }

    compararDatas(a, b) {
        var A = new Date(a.dataInicial)
        var B = new Date(b.dataInicial)
        if (A < B) {
          return 1;
        }
        if (A > B) {
          return -1;
        }
    
        return 0
    }

    render() {
        return (
            <NavBar navigation={this.props.navigation}  isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}

                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Historico de pedidos</Text>
                </View>
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, marginTop: 32 }}>
                    {this.state.historics.length > 0? 
                    <Picker 
                    style={{borderWidth: 1, borderColor: 'gray'}} 
                    placeHolder={"Selecionar periodo do sorteio"}
                    onValueChange={(itemValue) => {this.setState({selectedValue : itemValue, selectedHistoric : JSON.parse(itemValue)})}}
                    arrayLabelValue={[
                        {label : `${this.formatDate(this.state.historics[0].dataFinal)}`, value : JSON.stringify(this.state.historics[0])},
                        {label : `${this.formatDate(this.state.historics[1].dataFinal)}`, value : JSON.stringify(this.state.historics[1])},
                        {label : `${this.formatDate(this.state.historics[2].dataFinal)}`, value : JSON.stringify(this.state.historics[2])},
                    ]}></Picker>
                    : <Text></Text>}
                </View>
                <View style={{width: '100%', ...this.state.styles.mt8, paddingLeft: 32, paddingRight: 32}}>
                    <ButtonComponent icon={null} disabled={this.state.selectedHistoric == null} action={() => this.handleModalOrders()} variant={this.state.selectedHistoric == null? "secondary_filled" : "primary_filled"}>Buscar</ButtonComponent>
                </View>
                        {this.state.modalOpenend2? <ModalOrders navigation={this.props.navigation} orders={this.state.orders} onPress={() => {this.handleModalHistoric()}} /> : <Text></Text>}
            </NavBar>
            )
    }
}
