import * as React from 'react';
import { MainStyle } from '../assets/styles/MainStyle';
import {NavigateParams} from '../services/Navigator';
import { View,TouchableOpacity, Text, StyleSheet, Image, Linking, Alert} from 'react-native';
import printer_icon from '../assets/images/icons/print-solid.png';
import { getData } from '../services/StorageService';

export default class CardOrder extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            currentUrl: ""
        }

    }


    componentDidMount(){
      getData("current_url").then(res => {
        let url = res.includes("cartelaflash")? "cartelaflash" : res.includes("flashday")? "flashday" : "sorteflash";
        this.setState({currentUrl: `https://www.${url}.com/imprimir?pedido=${this.props.order._id}`});
      });
    }

  //   openURL = async (url) => {
  //     const supported = await Linking.canOpenURL(url);
  //     if (supported) {
  //       await Linking.openURL(url);
  //     } else {
  //       Alert.alert(`Don't know how to open this URL: ${url}`);
  //     }
  // }

  openURL = (url) => {

    this.setState({redirect : {status: true, value: 'Printer', params: {url: url}}})
  }




    render() {

        const styles = StyleSheet.create({
            dropDownButton: {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 48,
              borderBottomWidth: 1,
              backgroundColor: '#c2c2c2',
              borderRadius: 6
            },
            dropDown: {
              position: 'absolute',
              right: 32,
              top: 56,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#c2c2c2',
              width: 200,
              height: 72,
              zIndex: 5000,
              elevation: 8,
              borderRadius: 6
            },
            dotsIcon: {
              width: 28,
              height: 28
            },
            dotsIconTouch: {
              position: 'absolute',
              right: 12,
              top: 28
            },
            modal: {
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#c2c2c2'
            },
            modalContainer: {
              width: '100%',
              height: 320,
              backgroundColor: 'transparent',
              borderRadius: 12,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 12
            },
            appBar: {
              height: 64,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent : 'center',
              alignItems: 'center',
              paddingTop: 12
            },
            textName: {color: '#f7f9f7', fontSize: 20, fontWeight: 'bold'},
            container: {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              width : '100%'
            },
            vitrineContainer: {backgroundColor: 'white', marginTop: 12 },
            vitrineContainer2: {backgroundColor: 'white', width: 400, display: 'flex', justifyContent: 'center', marginTop : 32 },
            cardText: {color: '#272727', fontSize: 12, padding: 6, fontWeight: 'bold'},
            vitrineContent: { height: 256, backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 12 },
            arrowBtn: {height: '100%', width: '10%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'},
            arrowIcon:{    
              height: 128,
              width: 32},
            cardContainer:{borderWidth: 2 , borderColor: '#c2c2c2', borderRadius: 12, width: '98%', height: '100%', backgroundColor: '#c2c2c2', display: 'flex', flexDirection : 'column', border: '5px solid #c2c2c2', justifyContent: 'space-between'},
            cardRow:{backgroundColor: '#c2c2c2', borderWidth: 1 , borderColor: 'black', width: '100%',borderRadius: 8, height: 72, display: 'flex', flexDirection : 'row', border: '5px solid #c2c2c2'},
            cardCol:{borderWidth: 2 , borderColor: '#c2c2c2', width: '20%', borderRadius: 8, height: '100%', backgroundColor: 'white', display: 'flex', flexDirection : 'row', justifyContent: 'center', alignItems: 'center'},
            cardNumber: {fontSize: 24, fontWeight: 'bold', color: '#272727'},
            textWhite: {fontSize : 22, fontWeight : 'bold', color: '#F7F9F7'},
            textBase: {fontSize : 22, fontWeight : 'bold', color: 'gray'},
            row: {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            },
            col: {
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            },
            btnCarrinho: {
              display: 'flex',
              flexDirection: 'row',
              width: '90%',
              height: 64,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f7f9f7',
              borderRadius: 4
            },
            deleteCard: {
              display: 'flex',
              flexDirection: 'column',
              width: '70%',
              height: 64,
              alignItems: 'center',
              backgroundColor: 'white',
              justifyContent: 'center',
              borderColor: 'gray',
              borderWidth: 1,
              borderRadius: 4,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              paddingLeft: 12
          
            },
            btnDeleteCard: {
              display: 'flex',
              flexDirection: 'row',
              width: '20%',
              height: 64,
              alignItems: 'center',
              backgroundColor: '#d14d49',
              justifyContent: 'center',
              borderRadius: 4,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            },
            btnPayment: {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: 64,
              alignItems: 'center',
              justifyContent: 'center',
              borderColor: 'gray',
              borderWidth: 1,
              marginTop: 12,
              borderRadius: 12
            },
            scrollWidth: {paddingHorizontal: 12,width: 800, display: 'flex', flexDirection: 'row'},
            pagamentoText: {fontSize: 18, fontWeight: 'bold', color: 'white'},
            pagamentoLabel: {width: '100%', justifyContent: 'center', alignItems: 'center', padding: 6, backgroundColor: 'black'}
          });

        return (
            <View style={styles.vitrineContainer} >
            {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={this.state.redirect.params}></NavigateParams>  : <Text></Text>}
            <View style={styles.vitrineContent} >
                <View style={styles.cardContainer}>
                <View style={this.state.styles.row}>
                  <Text style={styles.cardText} >{`${this.props.currentCard.data.split('-')[2]+'-'+this.props.currentCard.data.split('-')[1]+'-'+this.props.currentCard.data.split('-')[0]} - Pedido: ${this.props.order.codigo} - Cartela: ${this.props.currentCard.codigo}`}</Text>
                  {this.props.order.recebido? <TouchableOpacity onPress={() => this.openURL(this.state.currentUrl)}>
                    <Image source={printer_icon} style={{width : 22, height: 22, marginLeft: 12}} /> 
                  </TouchableOpacity> : <Text></Text>}
                </View>
                  <View style={styles.cardRow}>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[0]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[0]) ? {...styles.cardNumber, color: 'white'} : styles.cardNumber}>{this.props.currentCard.numeros[0]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[1]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[1]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[1]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[2]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[2]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[2]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[3]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[3]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[3]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[4]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[4]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[4]}</Text></View>
                  </View>
                  <View style={styles.cardRow}>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[5]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[5]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[5]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[6]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[6]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[6]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[7]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[7]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[7]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[8]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[8]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[8]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[9]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[9]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[9]}</Text></View>
                  </View>
                  <View style={styles.cardRow}>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[10]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[10]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[10]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[11]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[11]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[11]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[12]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[12]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[12]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[13]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[13]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[13]}</Text></View>
                    <View style={this.props.numbers.includes(this.props.currentCard.numeros[14]) ? {...styles.cardCol, backgroundColor : '#1ca861'} : styles.cardCol}><Text style={this.props.numbers.includes(this.props.currentCard.numeros[14]) ? {...styles.cardNumber, color: 'white'}: styles.cardNumber}>{this.props.currentCard.numeros[14]}</Text></View>
                  </View>
                </View>
            </View>
          </View>
            
            )
    }
}
