import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,ScrollView, Image, Text} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import Video from '../components/Video';
import NavBar from '../components/NavBar';
import CardOrder from '../components/CardOrder';
import layers_icon from '../assets/images/icons/layer-group-solid.png';
import down_icon from '../assets/images/icons/square-caret-down-solid.png';
import { getClientOrders, getCurrentNumbers, getLive, getStatusStore } from '../services/ApiServices';
import { getData } from '../services/StorageService';
export default class Orders extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            count: 0,
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            orders: [],
            live: null,
            isLoading : false,
            currentNumbers: [],
            timeOut: null,
            sorteioStatus: false
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        this.counter();
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            getClientOrders(res, this.state.user._id).then(res => {
                this.setState({orders : Array.isArray(res)? res : []})
                this.setState({isLoading : false});
            });
            getStatusStore(res).then(res => this.setState({sorteioStatus : res.sorteioStatus}))
            getCurrentNumbers(res).then(res => this.setState({currentNumbers : res}));
            getLive(res).then(res => {this.setState({live : res});})
        })
    }

    async counter(){
        var interval = setInterval(() => {
            this.setState({count : this.state.count+1})
        }, 1000)
        this.setState({timeOut : interval})
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        return url;
    }

    updateCurrentNumbers(){
        getCurrentNumbers(this.state.currentUrl).then(res => this.setState({currentNumbers : res, count: 0}));
    }

    render() {
        return (
            <NavBar navigation={this.props.navigation}  isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Minhas cartelas</Text>
                    {/* <Image source={layers_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
                </View>
                <View style={{...this.state.styles.row, ...this.state.styles.alignCenter, justifyContent: 'space-around', ...this.state.styles.mt8}}>
                    <View style={{width: '49%' }}>
                        <ButtonComponent disabled={false} action={() => {clearInterval(this.state.timeOut); this.setState({redirect : {status: true, value: 'PendingPayment'}})}} variant="warning_filled">Conferir pendentes</ButtonComponent>
                    </View>
                    <View style={{width: '49%' }}>
                        <ButtonComponent disabled={false} action={() =>  {clearInterval(this.state.timeOut); this.setState({redirect : {status: true, value: 'HistoricOrders'}})}} variant="primary_filled">Historico de cartelas</ButtonComponent>
                    </View>
                </View>
                {this.state.live? <Video hashLive={this.state.live}></Video> : <Text></Text>}
                <View style={{width: '100%', ...this.state.styles.mt64, paddingLeft: 32, paddingRight: 32}}>
                    <ButtonComponent icon={down_icon} disabled={this.state.count < 20} action={() => this.updateCurrentNumbers()} variant={this.state.count < 20? "secondary_filled" : "dark_filled"}>{this.state.count < 20 ? `${20 - this.state.count}s aguarde...` : "Marcar numeros"}</ButtonComponent>
                </View>
                <ScrollView>
                    <View style={this.state.styles.container}>
                    {this.state.orders.map(order =>
                        order.cartelas.map(card => 
                            <CardOrder navigation={this.props.navigation} numbers={this.state.sorteioStatus? this.state.currentNumbers : []} order={order} currentCard={card}></CardOrder>
                        ) 
                    )}
                    </View>
                </ScrollView>          
 
            </NavBar>
            )
    }
}
