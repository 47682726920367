import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, Text, Image, Linking, Alert} from 'react-native';
import cfLogo from '../assets/images/logos/cf_logo.png';
import fdLogo from '../assets/images/logos/fd_logo.png';
import sfLogo from '../assets/images/logos/sf_logo.png';
import home_icon from '../assets/images/icons/house-solid.png';
import menu_icon from '../assets/images/icons/bars-solid.png';
import cart_icon from '../assets/images/icons/cart-shopping-solid-dark.png';
import book_icon from '../assets/images/icons/book-open-solid-dark.png';
import layers_icon from '../assets/images/icons/layer-group-solid-dark.png';
import trophy_icon from '../assets/images/icons/trophy-solid-dark.png';
import user_icon from '../assets/images/icons/user-solid-dark.png';
import question_icon from '../assets/images/icons/circle-question-solid.png';
import logout_icon from '../assets/images/icons/right-from-bracket-solid.png';
import lupa_icon from '../assets/images/icons/magnifying-glass-solid.png';
import printer_icon from '../assets/images/icons/print-solid.png';
import chart_icon from '../assets/images/icons/chart-line-solid-dark.png';
import pix_icon from '../assets/images/icons/pix.png';
import { getData, removeData } from '../services/StorageService';
import Loading from '../components/Loading';
import { getSAC } from '../services/ApiServices';
export default class NavBarSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: ''},
            user : {vendedorApp: true},
            styles : styles,
            modalOpened: false,
            currentSystem: "",
            systemColor: 'white',
            currentLogo: sfLogo,
            sac: '99999999999'
        }
    }
    getFullSystemName(currentSystem){
      return currentSystem == "cf"?  this.setState({currentLogo : cfLogo}) : currentSystem == "fd"? this.setState({currentLogo : fdLogo})  : this.setState({currentLogo : sfLogo}) 
    }

    async getUserAtInit(){
        try {
          let sys = await getData("current_system");
          let user = await getData(sys+"_user");
          if(user)
            this.setState({user : JSON.parse(user)});
        } catch (error) {
          console.log(error)
        }
    }

    componentDidMount(){
      getSAC().then(res => {this.setState({sac: res})})
      this.getUserAtInit();

      getData("current_system").then(res => {
        this.setState({currentSystem: res});
        this.getFullSystemName(res);
    });
      getData("current_system_color").then(res => this.setState({systemColor : res}))
    }

    handleOpenModal(){
      this.setState({modalOpened: !this.state.modalOpened})
    }

        
    openURL = async (url) => {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
      } else {
        Alert.alert(`Don't know how to open this URL: ${url}`);
      }
  }

  logout(){
    removeData(this.state.currentSystem + "_system_seller_auth").then(res => {
    removeData(this.state.currentSystem + "_user").then(res => {
      removeData("current_system").then(res => {
        removeData("current_url").then(res => {
          removeData("current_system_color").then(res => {
              window.location.href = "https://sorteflashapp.com";
          })

        })
      })
    })
  })
    //this.setState({redirect : {status: true, value: ''}});

    
  }
    render() {
        return (
          <View style={this.state.styles.viewPort}>
                        {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
          <View style={this.state.styles.container}>
            {this.props.isLoading? <Loading></Loading> : <Text></Text>}
            {this.state.modalOpened ? <TouchableOpacity onPress={() => this.handleOpenModal()} style={{...this.state.styles.container, position: 'absolute', zIndex: 3000, width: '100%', height: '100%', backgroundColor: 'rgba(1,1,1,0.3)'}}>
              <View style={{position: 'absolute',zIndex: 3001, top: 32, right: 32,  width: 256, height: 480, backgroundColor: 'white', borderRadius: 6, display: 'flex', flexDirection: 'column'}}>

                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'StoreSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={cart_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Escolher cartelas</Text>
                </TouchableOpacity>  
                <TouchableOpacity onPress={() =>  this.setState({redirect : {status: true, value: 'OrdersSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={layers_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Pedidos</Text>
                </TouchableOpacity>
                {/* {this.state.user.vendedorApp?  */}
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'ReceiveOrders'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                    <Image source={lupa_icon} style={{width: 18, height: 18}}></Image>
                    <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Receber Pedidos</Text>
                  </TouchableOpacity>
               {/* : <Text></Text>}   */}

                {this.state.user.vendedorApp? 
                      <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'SerieSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                      <Image source={printer_icon} style={{width: 18, height: 18}}></Image>
                      <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Compartilhar panfleto</Text>
                    </TouchableOpacity> 
                : <Text></Text>}  
 
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'ReportsSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={chart_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Relatorio</Text>
                </TouchableOpacity>  
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'WithdrawSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={pix_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Saque de comissão</Text>
                </TouchableOpacity> 
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'RegulationSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={book_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Regulamento</Text>
                </TouchableOpacity>   
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'HistoricWinnersSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={trophy_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Ganhadores</Text>
                </TouchableOpacity>   
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'ProfileSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={user_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Perfil</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'ClientsSeller'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={user_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Clientes</Text>
                </TouchableOpacity>   
                <TouchableOpacity onPress={() => this.openURL('https://api.whatsapp.com/send?phone=55'+this.state.sac+'&text=Preciso de ajuda')} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={question_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Ajuda</Text>
                </TouchableOpacity> 
                <TouchableOpacity onPress={this.logout.bind(this)} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={logout_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Sair</Text>
                </TouchableOpacity>     
              </View>
            </TouchableOpacity>
             : ""}
            <View style={{height: 40, width: '100%', ...this.state.styles.row, justifyContent: 'space-around' , backgroundColor : this.state.systemColor, ...this.state.styles.alignCenter}}>
              <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: ''}})}>
                <Image source={home_icon} style={{width: 18, height: 18}}></Image>
              </TouchableOpacity>
              <View style={{width: 'auto', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignContent:  'center'}}>         
              <Image source={this.state.currentLogo}style={{width: 120, height: 28}}></Image>
              <Text style={{color: 'white'}}>Olá , {this.state.user?.nome}!</Text>
              </View>
              <TouchableOpacity onPress={() => this.handleOpenModal()}>
                <Image source={menu_icon} style={{width: 18, height: 18}}></Image> 
              </TouchableOpacity>   
            </View>
            {/* <ScrollView> */}
            <View style={this.state.styles.container}>
              {this.props.children}
            </View>
            {/* </ScrollView> */}
          </View>
          </View>
        )
    }
}
