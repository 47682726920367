import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,ScrollView, Image, Text, Clipboard} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import pix_icon from '../assets/images/icons/pix.png';
import { getData } from '../services/StorageService';
import NavBarSeller from '../components/NavBarSeller';
export default class PixPaymentSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            modalOpenend: false,
            systemColor: 'white',
            desconto: 0,
            params: null
        }
    }

    componentDidMount(){
        getData("props").then(res => {
            this.setState({params :  JSON.parse(res)})
        })
        getData("current_system_color").then(res => this.setState({systemColor : res}))
    }

    handleModalOrder(){
        this.setState({modalOpenend : !this.state.modalOpenend})
    }

    render() {
        if(this.state.params == null){}
        else{
        return (
            <NavBarSeller navigation={this.props.navigation} >
                <View style={{height:64, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    {/* <Image source={pix_icon} style={{width : 22, height: 22}} />  */}
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight, marginLeft: 12}}>Pix copia e cola</Text>
                </View>
                <View style={{width: '100%', paddingLeft: 8, paddingRight: 8, ...this.state.styles.col}}>
                    <ScrollView>
                        <View style={{...this.state.styles.mt8, width: '100%',  height: 128, ...this.state.styles.myShadow, ...this.state.styles.col}}>
                            <Text style={{marginLeft: 8,...this.state.styles.mt8,...this.state.styles.bold}}>{this.state.params.order.cartelas[0].data} - Pedido:{this.state.params.order.codigo} - Cartelas: {this.state.params.order.cartelas.length}</Text>
                            <Text style={{marginLeft: 180,...this.state.styles.mt8,...this.state.styles.bold}} >Unidade: R$ {parseFloat(this.state.params.order.cartelas[0].valor).toFixed(2)}</Text>
                            {this.state.params.order.cambistaId.vendedorApp? 
                                <Text style={{marginLeft: 180,...this.state.styles.mt8,...this.state.styles.bold}}>Comissão: R${this.state.params.order.cartelas.length * this.state.params.order.desconto }</Text>    
                            : <Text></Text>}
                            {this.state.params.order.cambistaId.vendedorApp? 
                                <Text style={{marginLeft: 180,...this.state.styles.mt8,...this.state.styles.bold}}>Total: R${(this.state.params.order.cartelas.length * parseFloat(this.state.params.order.cartelas[0].valor).toFixed(2)) - (this.state.params.order.cartelas.length * this.state.params.order.desconto )}</Text>     
                            : 
                            <Text style={{marginLeft: 180,...this.state.styles.mt8,...this.state.styles.bold}}>Total: R${this.state.params.order.cartelas.length * parseFloat(this.state.params.order.cartelas[0].valor).toFixed(2)}</Text>    
                            }
                        </View>
                    </ScrollView>
                    <View style={{...this.state.styles.mt8, width: '100%',  height: 96, ...this.state.styles.myShadow, ...this.state.styles.col}}>
                            <Text style={{marginLeft: 8,...this.state.styles.mt8,...this.state.styles.bold}}>{this.state.params.order.codigoCopiaCola}</Text> 
                    </View>
                    <View style={{width: '100%', ...this.state.styles.mt32}}>
                        <ButtonComponent disabled={false} action={() => Clipboard.setString(this.state.params.order.codigoCopiaCola)} variant="primary_filled">Copiar</ButtonComponent>
                    </View>
                    <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><Text style={{...this.state.styles.fs14,...this.state.styles.bold , ...this.state.styles.cDanger, ...this.state.styles.mt32}}>Abra o App do banco e pague com o Pix copia e cola.</Text></View>
                    <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><Text style={{...this.state.styles.fs14,...this.state.styles.bold , ...this.state.styles.cDanger, ...this.state.styles.mt8}}>Após confirmar o pagamento confira em 'Minhas cartelas'</Text></View>           
                </View>
            </NavBarSeller>
            )
        }
    }
}
