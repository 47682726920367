import * as React from 'react';
import { MainStyle } from '../assets/styles/MainStyle';
import NavBarSeller from '../components/NavBarSeller';
import { View,TouchableOpacity, Clipboard, TextInput, Text, Alert,Image, Share} from 'react-native';
import Picker from '../components/Picker';
import ButtonComponent from '../components/ButtonComponent';
import { singupPasswordSeller, updateUsuario} from '../services/ApiServices';
import { getData, setData } from '../services/StorageService';

export default class ProfileSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            currentUrl: "",
            currentSystem: "",
            nome: "",
            telefone: "",
            email: "",
            chavepix: "",
            cidade: "",
            estado: "",
            cpf: ""
        }
    }

    componentDidMount(){
        this.initializeDataState();
    }


    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user),             
        nome: JSON.parse(user).nome,
        cpf: JSON.parse(user).cpf,
        telefone: JSON.parse(user).telefone,
        email: JSON.parse(user).email,
        chavepix: JSON.parse(user).chavepix,
        cidade: JSON.parse(user).cidade,
        estado: JSON.parse(user).estado });
        return {url, user: JSON.parse(user)};

    }

    setPhone(value){
        if(value.length > this.state.telefone.length){
            let subMask = "?? ????? ????";
            value.split('').map((character,index) => {
                if(character != ' ' && !isNaN(character)){
                    subMask = subMask.replace('?', character);
                    if(index == value.length - 1){
                        this.setState({telefone : subMask.split('?')[0]});
                    }
                }

            });
        }
        else{
            this.setState({telefone : value})
        }
    }

    submitForm(){
        this.state.email = this.state.email.replace(/\s/g,'');
        if(this.state.nome.length < 3){
            alert("Nome inválido!");
            Alert.alert("Nome inválido!");
        }
        else if(this.state.telefone.length < 13){
            alert("Telefone inválido!");
            Alert.alert("Telefone inválido!");
        }
        else if(this.state.cpf.length < 10){
            alert("CPF inválido!");
            Alert.alert("CPF inválido!");
        }
        else if(this.state.cidade.length < 3){
            alert("Cidade inválida!");
            Alert.alert("Cidade inválida!");
        }
        else if(this.state.estado.length < 2 || this.state.estado.length > 2){
            alert("Estado inválida!");
            Alert.alert("Estado inválida!");
        }
        else if(this.state.email.length < 8 || !this.state.email.toLowerCase().includes("@icloud.com") && !this.state.email.toLowerCase().includes("@gmail.com") && !this.state.email.toLowerCase().includes("@hotmail.com") && !this.state.email.toLowerCase().includes("@outlook.com")){
            alert("E-mail inválido!");
            Alert.alert("E-mail inválido!");
        }
        else{
            updateUsuario(this.state.currentUrl, {
                _id: this.state.user._id,
                nome: this.state.nome, 
                telefone: this.state.telefone, 
                cidade: this.state.cidade,
                estado: this.state.estado,
                cpf: this.state.cpf,
                email: this.state.email,
                chavepix: this.state.chavepix,
            }).then(res => {
                setData(`${this.state.currentSystem}_user`, JSON.stringify({...this.state.user,
                    _id: this.state.user._id,
                    nome: this.state.nome, 
                    telefone: this.state.telefone, 
                    cidade: this.state.cidade,
                    estado: this.state.estado,
                    cpf: this.state.cpf,
                    email: this.state.email,
                    chavepix: this.state.chavepix,
                }));
                alert("Atualizado com sucesso!");
                Alert.alert("Atualizado com sucesso!");
            })
        }

    }


    async onShare(link){
        try{
        const result = await Share.share({
            content: link,
            message: link,
            url: link

          });
          if (result.action === Share.sharedAction) {
            if (result.activityType) {
              // shared with activity type of result.activityType
            } else {
              // shared
            }
          } else if (result.action === Share.dismissedAction) {
            // dismissed
          }
        } catch (error) {
          Alert.alert(error.message);
        }
      };

    render() {
        console.log(this.state.estado)
        return (
            <NavBarSeller navigation={this.props.navigation} >
            <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Perfil de vendedor</Text>
                {/* <Image source={user_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
            </View>
            <View style={{...this.state.styles.container, padding : 32, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                
                
                <TextInput disabled={true} style={{backgroundColor: "#f2f2f2",...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Nome'
                        placeHolder={this.state.user.nome}
                        onChangeText={(value) => {this.setState({nome : value})}}
                        value={this.state.nome}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
            
                <TextInput disabled={true}  style={{backgroundColor: "#f2f2f2",...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}}
                        placeholder='Telefone'
                        placeHolder={this.state.user.telefone}
                        onChangeText={(value) => {this.setState({telefone : value}); this.setPhone(value)}}
                        value={this.state.telefone}
                        keyboardType="numeric"
                        maxLength={13}
                ></TextInput>

                <TextInput disabled={true}  style={{backgroundColor: "#f2f2f2",...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}}
                        placeholder='CPF'
                        placeHolder={this.state.user.cpf}
                        onChangeText={(value) => {this.setState({cpf : value});}}
                        value={this.state.cpf}
                        keyboardType="numeric"
                        maxLength={20}
                ></TextInput>

                <TextInput disabled={true}  style={{backgroundColor: "#f2f2f2",...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Cidade'
                        placeHolder={this.state.user.cidade}
                        onChangeText={(value) => {this.setState({cidade : value})}}
                        value={this.state.cidade}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
                {/* <View style={{...this.state.styles.mt8, width: '100%',  height: 'auto' , ...this.state.styles.myShadow}} >
                    <Picker placeHolder={"Selecionar estado"} onValueChange={(value) => this.setState({estado: value})} arrayLabelValue={[
                        {label : "Acre", value :"AC"},
                        {label : "feses", value :"."},
                        {label : "Alagoas", value :"AL"},
                        {label : "Amapá", value :"AP"},
                        {label:"Amazonas", value:"AM"},
                        {label:"Bahia", value:"BA"},
                        {label:"Ceará",value:"CE"},
                        {label:"Distrito Federal", value:"DF"},
                        {label:"Espírito Santo",value:"ES" },
                        {label:"Goiás" ,value:"GO"},
                        {label:"Maranhão" , value:"MA"},
                        {label:"Mato Grosso" , value:"MT"},
                        {label:"Mato Grosso do Sul",value:"MS"},
                        {label:"Minas Gerais", value:"MG"},
                        {label:"Pará", value:"PA"},
                        {label: "Paraíba" , value:"PB"},
                        {label: "Paraná" , value:"PR"},
                        {label: "Pernambuco" , value:"PE"},
                        {label: "Piauí", value:"PI"},
                        {label: "Rio de Janeiro", value:"RJ"},
                        {label:"Rio Grande do Norte" , value:"RN"},
                        {label: "Rio Grande do Sul", value:"RS"},
                        {label:"Rondônia", value:"RO"},
                        {label:"Roraima", value:"RR"},
                        {label: "Santa Catarina", value:"SC"},
                        {label: "São Paulo", value:"SP"},
                        {label: "Sergipe", value:"SE"},
                        {label:"Tocantins" , value:"TO"}
                    ]}></Picker>
                </View> */}
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Chave pix'
                        placeHolder={this.state.user.nome}
                        onChangeText={(value) => {this.setState({chavepix : value})}}
                        value={this.state.chavepix}
                        keyboardType="default"
                ></TextInput>
                <View style={{width: '100%', ...this.state.styles.mt8}}>
                    <ButtonComponent disabled={false} action={this.submitForm.bind(this)} variant="primary_filled">Atualizar </ButtonComponent>
                </View>
                <Text style={{...this.state.styles.mt64, ...this.state.styles.bold, width: '100%',  height: 32,}}>
                    Link de compartilhamento
                </Text>
                {/* <TouchableOpacity style={{paddingLeft: 12, paddingRight: 12, width: '100%'}} onPress={() => {  this.onShare("https://www.sorteflashapp.com/?seller="+this.state.user._id)}}>
                    <Text style={{...this.state.styles.mt8, ...this.state.styles.cSuccess,...this.state.styles.bold,  width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}}> 
                        {"https://www.sorteflashapp.com/?seller="+this.state.user._id}
                    </Text>
                </TouchableOpacity>
                 */}
                <TextInput style={{backgroundColor: "#f2f2f2", paddingLeft: 12, paddingRight: 12, width: '100%', fontSize: 16, height: 64}} value={"https://www.sorteflashapp.com/?seller="+this.state.user._id}>


                </TextInput>
                <View style={{width: '100%', ...this.state.styles.mt8}}>
                    <ButtonComponent variant="warning_filled" action={() => {Clipboard.setString("https://www.sorteflashapp.com/?seller="+this.state.user._id); alert("Cópiado com sucesso!")}} >Copiar </ButtonComponent>
                </View>

            </View>
            </NavBarSeller>
            )
    }
}
