import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TextInput, TouchableOpacity, Image, Text, Alert, ScrollView, Linking} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import printer_icon from '../assets/images/icons/print-solid.png';
import { getClientsBySeller, getSellerSeries, setNewOrderBySeller, updateSellerSerie } from '../services/ApiServices';
import { getData } from '../services/StorageService';
import user_icon from '../assets/images/icons/user-plus-solid.png';
import NavBarSeller from '../components/NavBarSeller';
import lupa_icon from '../assets/images/icons/magnifying-glass-solid.png';
import whatsapp_icon from '../assets/images/icons/whatsapp.png';
import { Base64 } from 'js-base64';
export default class SerieSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            series: [],
            setSelected: null,
            clients: [],
            cardList: [],
            cardLength: 0,
            isLoading: false,
            code: "",
            _scrollView : null,
            isStriped : false
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            getClientsBySeller(res, this.state.user._id).then(res => this.setState({clients : res.sort(this.sortByName)}))
            getSellerSeries(res, this.state.user._id).then(res => {
                this.setState({series : Array.isArray(res)? res : []});
                this.setState({isLoading : false});
                let l = 0;
                res.map(serie => {l += serie.cartelas.length});
                this.setState({cardLength : l})
            })
        })
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});

        return url;
    }


    sortByName(a,b){
        if (a.nome.toLowerCase() < b.nome.toLowerCase() ) {
            return -1;
          }
          if (a.nome.toLowerCase()  > b.nome.toLowerCase() ) {
            return 1;
          }
          return 0;
    }

    submitOrder(card, client, serie, index){
        this.setState({isLoading : true});
        setNewOrderBySeller(this.state.currentUrl, {
            cartelas: [card],
            clienteId: client,
            cambistaId: this.state.user,
            gerenteId: this.state.user.tutorId,
            data: new Date()
        }).then(res => {
            serie.cartelas.splice(index,1);
            this.setState({isLoading : false});
            window.screen ? alert("Validado com sucesso!") : console.log("Validado com sucesso!");
            Alert.alert("Validado com sucesso!");
            delete this.state["client"+card.codigo];
            updateSellerSerie(this.state.currentUrl, serie);
        })

    }

    setAutoCompleteCliente(key, value){
        this.setState({[key] : {nome : value}})
    }

    scrollToRow() {
        var elIndex = 0;
        var totalIndex = 0;
        this.state.series.map((serie,sindex) => {
            serie.cartelas.map((card, index) => {
                if(card.codigo == this.state.code){
                    elIndex = totalIndex;
                }
                totalIndex += 1;
            })
        })
        this.state._scrollView.scrollTo({y: elIndex * 136,animated: true})
      }

      openURL = async (url) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
          await Linking.openURL(url);
        } else {
          Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    render() {

        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Cartelas panfleto</Text>
                    {/* <Image source={printer_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
                </View>
                <View style={{width: '100%', ...this.state.styles.mt8, ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    <Text style={{...this.state.styles.fs18, ...this.state.styles.bold}}> restam {this.state.cardLength} cartelas</Text>
                    {/* <Image source={printer_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
                </View>
                <View style={{width: '100%', height: 56, borderWidth: 1,  ...this.state.styles.mt8, ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    {/* <TextInput onBlur={(e) => this.setState({code : e.nativeEvent.text})} style={{width: '70%', height: 32, borderWidth: 1, borderColor: "gray", borderRadius: 4, paddingLeft: 12}} placeholder={"código de cartela"}></TextInput> */}
                    <TextInput onChangeText={(text) => this.setState({code : text})} style={{width: '70%', height: 32, borderWidth: 1, borderColor: "gray", borderRadius: 4, paddingLeft: 12}} placeholder={"código de cartela"}></TextInput>
                    <TouchableOpacity onPress={this.scrollToRow.bind(this)}><Image  style={{marginLeft: 12, width: 24, height: 24}} source={lupa_icon}></Image></TouchableOpacity> 
                </View>
                <ScrollView   ref={view => this.setState({_scrollView : view})}  >
                <View style={{width: '100%', ...this.state.styles.mt8, ...this.state.styles.col}}>
                    {this.state.series.map((serie, sindex) => 
                        serie.cartelas.map((card,index) => {
                            this.state.isStriped = !this.state.isStriped;
                        return <View key={`${sindex}${index}`} style={{backgroundColor: this.state.isStriped ? '#e9f5f9' :'white' , borderColor: '#e9f5f9', borderWidth: 1,  width: '100%', height: 64, ...this.state.styles.mt8, ...this.state.styles.col, padding: 8, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter }}>
                            <View style={{width: '100%' , ...this.state.styles.row, alignItems: 'center', justifyContent: 'space-evenly'}}>
                                <Text style={{...this.state.styles.bold}}>{card.codigo}</Text>
                                {/* <TextInput style={{...this.state.styles.mt8, width: '70%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                                        placeholder='Nome do cliente'
                                        onChangeText={(value) => {this.setAutoCompleteCliente("client"+card.codigo, value)}}
                                        keyboardType="default"
                                        value={this.state["client"+card.codigo]? this.state["client"+card.codigo].nome : ""}
                                ></TextInput> */}
                            </View>
                            {/* {this.state["client"+card.codigo] && this.state["client"+card.codigo].nome !== "" && !this.state["client"+card.codigo]._id? 
                                    <View style={{width: '90%', height: 'auto', ...this.state.styles.col, ...this.state.styles.myShadow}}>
                                        {this.state.clients.map(client => {
                                        if(client.nome.toLowerCase().includes(this.state["client"+card.codigo].nome.toLowerCase()) )
                                            return <TouchableOpacity onPress={() => this.setState({["client"+card.codigo] : client})}>
                                                <Text style={{...this.state.styles.bold, marginLeft : 12}}>{client.nome} - {client.telefone}</Text>
                                            </TouchableOpacity>
                                        })}
                                    </View>
                                : <Text></Text>} */}
                            <View style={{width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.mt8, alignItems: 'center', justifyContent: 'flex-end', paddingRight: 14}}>
                                <TouchableOpacity onPress={() => {this.openURL(`https://api.whatsapp.com/send?text=https://sorteflashapp.com/?qrcode=${Base64.encode(JSON.stringify({serieId: serie._id, cartelaCodigo: card.codigo, cambistaId: this.state.user._id, currentSystem: this.state.currentSystem, currentUrl: this.state.currentUrl, systemColor: this.state.systemColor}))}`)}} >
                                    <Image source={whatsapp_icon} style={{width : 40, height: 40}} /> 
                                </TouchableOpacity>
                                {/* <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'ClientSingUpSellerScreen'}})} >
                                    <Image source={user_icon} style={{width : 32, height: 22, marginRight: 12}} /> 
                                </TouchableOpacity>
                                <ButtonComponent disabled={false} action={() => {this.state["client"+card.codigo] && this.state["client"+card.codigo]._id ? this.submitOrder(card, this.state["client"+card.codigo], serie, index) : Alert.alert("Cliente não cadastrado");}} variant="primary_filled"> Validar </ButtonComponent> */}
                            </View>
                        </View> 
                            })
                        )}
                </View>
                </ScrollView>         
            </NavBarSeller>
            )
    }
}
