import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,Image, Text, Alert, TouchableOpacity} from 'react-native';
import Picker from '../components/Picker';
import NavBarSeller from '../components/NavBarSeller';
import { getSellerOrders, getSellerReports, getHistorics, getDesconto , getSellerOrdersHistoric} from '../services/ApiServices';
import chart_icon from '../assets/images/icons/chart-line-solid.png';
import { getData } from '../services/StorageService';
import lupa_icon from '../assets/images/icons/magnifying-glass-solid.png';
import moment from "moment";
export default class ReportsSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            orders: [],
            filteredOrders: [],
            selectedValue: null,
            selectedHistoric: null,
            selectedValueFilter: null,
            historics: [
            ],
            report: {cartelas: 0, apurado: 0, comissao: 0, banca: 0, whatssapp : 0, pix : 0, credito:0, panfleto: 0,  site: 0, cartelasPix: 0, cartelasWhatssapp: 0, cartelasPanfleto: 0, cartelasSite: 0, cartelasCredito: 0},
            isLoading: false,
            desconto: 0
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            this.setState({isLoading : false});
            getHistorics(res.url).then(res => this.setState({historics : Array.isArray(res)? res : []}))
            getSellerOrders(res.url, res.user._id).then(res => {this.setState({orders : Array.isArray(res)? res : [], filteredOrders: Array.isArray(res)? res : []}); this.generateReport(res) } )
        })
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        getDesconto(url).then(res => {
            if(JSON.parse(user).vendedorApp){
                this.setState({user : {...JSON.parse(user), comissao: res}});
            }
            this.setState({desconto: res})

        });

        return {url, user: JSON.parse(user)};

    }

    formatDate(data) {
        return moment(data).add(0, 'days').format('DD-MM-YYYY')
    }

    searchReport(){
        this.setState({isLoading : true, filteredOrders: [], selectedValueFilter: null });
        if(this.state.selectedHistoric){
            
            getSellerOrdersHistoric(this.state.currentUrl, this.state.user._id, this.state.selectedHistoric).then(res => {
                this.setState({orders : Array.isArray(res)? res : [], filteredOrders: Array.isArray(res)? res : []});
                this.generateReport(res);
                this.setState({isLoading : false});
            })
            

        }
        else{
            this.setState({isLoading : false});
            alert("Você deve selecionar um historico antes!");
            Alert.alert("Você deve selecionar um historico antes!");
        }
    }


    handlerFilter(value){
        let array = []
        if(value == null){
            array = this.state.orders;
        }
        else {
            this.state.orders.map(order => {
                if(value == "pix" && order.isPixPayment &&  !order.isPanfleto ){array.push(order)}
                else if(value == "site" && order.transacaoId && !order.isApp && order.transacaoId.documento != "Documento Avulso"){array.push(order)}
                else if(value == "panfleto" && order.isPixPayment &&  order.isPanfleto){array.push(order)}
                else if(value == "whatsapp" && !order.isPixPayment &&  !order.isPanfleto){array.push(order)}
                
            })
        }

        this.setState({filteredOrders: array, selectedValueFilter: value})
        this.generateReport(array);
    }

    generateReport(orders){
        if(orders.length > 0){
            if(this.state.user.vendedorApp){
                //this.state.user.comissao = this.state.desconto;
                //this.setState({user: {...this.state.user, comissao: this.state.desconto}})
            }
            let cartelas = 0;
            let pix = 0;
            let whatssapp = 0;
            let panfleto = 0;
            let site = 0;

            let cartelasCredito = 0;
            orders.map(order => {
                if(order.isCredit){
                    cartelasCredito += order.cartelas.length;
                }
                else{
                    cartelas += order.cartelas.length;
                    if(order.isPixPayment && !order.isPanfleto)
                        pix +=  order.cartelas.length;
                    if(!order.isPixPayment)
                        whatssapp +=  order.cartelas.length;
                    if(order.isPanfleto)
                        panfleto +=  order.cartelas.length;
                    if(order.transacaoId && !order.isApp && order.transacaoId.documento != "Documento Avulso")
                        site +=  order.cartelas.length;
                }


            })

            this.setState({report : {
                cartelas : cartelas, 
                cartelasPix: pix,
                cartelasWhatssapp: whatssapp,
                cartelasPanfleto: panfleto,
                cartelasSite: site,
                cartelasCredito: cartelasCredito,
                credito: cartelasCredito * parseFloat(orders[0].cartelas[0].valor).toFixed(2),
                apurado: cartelas * parseFloat(orders[0].cartelas[0].valor).toFixed(2),
                comissao: cartelas * this.state.user.comissao,
                banca: (whatssapp * parseFloat(orders[0].cartelas[0].valor).toFixed(2)) - (cartelas * this.state.user.comissao), 
                whatssapp : (whatssapp * parseFloat(orders[0].cartelas[0].valor).toFixed(2)), 
                pix : (pix * parseFloat(orders[0].cartelas[0].valor).toFixed(2)), 
                panfleto: (panfleto * parseFloat(orders[0].cartelas[0].valor).toFixed(2)), 
                site: (site * parseFloat(orders[0].cartelas[0].valor).toFixed(2)) 
        }})
    }
    }

    render() {
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Relatório</Text>
                    {/* <Image source={chart_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
                </View>
                <View style={{...this.state.styles.row, ...this.state.styles.alignCenter, justifyContent: 'center', ...this.state.styles.mt8}}>
                    <View style={{width: '40%' , borderWidth: 1, borderColor: 'gray', borderRadius: '4px'  }}>
                    {this.state.historics.length > 0? 
                    <Picker 
                    placeHolder={"Periodo do sorteio"}
                    onValueChange={itemValue => {this.setState({selectedValue : itemValue, selectedHistoric : JSON.parse(itemValue)});}}
                    arrayLabelValue={[
                        {label : `${this.formatDate(this.state.historics[0].dataFinal)}`, value : JSON.stringify(this.state.historics[0])},
                        {label : `${this.formatDate(this.state.historics[1].dataFinal)}`, value : JSON.stringify(this.state.historics[1])},
                        {label : `${this.formatDate(this.state.historics[2].dataFinal)}`, value : JSON.stringify(this.state.historics[2])},
                    ]}></Picker>
                    : <Text></Text>}
                    </View>
                    <View style={{marginLeft: 8, width: 86, borderWidth: 1, borderColor: 'gray', borderRadius: '4px' }}>
                    <Picker  onValueChange={(itemValue, itemIndex) => { this.handlerFilter(itemValue)}} 
                        placeHolder = {"Todos"}
                        arrayLabelValue={[
                            {label : "Afiliados", value :"pix"},
                            {label:"Site", value:"site"},
                            {label:"Panfleto", value:"panfleto"},
                            {label : "Whatssapp", value :"whatsapp"},
                        ]}></Picker>
  
                    </View>
                    <View style={{width: 16}}>
                        <TouchableOpacity onPress={() => this.searchReport()}>
                            <Image source={lupa_icon} style={{width : 22, height: 22, marginLeft: 12}} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{...this.state.styles.col, marginTop: 24, paddingLeft: 4, paddingRight: 4}}>

                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "#b7edc2"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Pré-pago</Text></View>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Q. cartelas</Text></View>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Arrecadado</Text></View>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Comissão</Text></View>        
                    </View>
                    
                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "white"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>Link afiliado</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>{this.state.report.cartelasPix}</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.pix}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.cartelasPix * this.state.user.comissao}</Text></View> 
                    </View>
                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "#ebedeb"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>Panfleto</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>{this.state.report.cartelasPanfleto}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.panfleto}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.cartelasPanfleto * this.state.user.comissao}</Text></View> 
                    </View>
                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "#ebedeb"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>Crédito</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>{this.state.report.cartelasCredito}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.credito}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.cartelasCredito * this.state.user.comissao}</Text></View> 
                    </View>
                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "white"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Total</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>{this.state.report.cartelasPix + this.state.report.cartelasPanfleto}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>R${this.state.report.pix + this.state.report.panfleto}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>R${(this.state.report.cartelasPix + this.state.report.cartelasPanfleto) * this.state.user.comissao}</Text></View>   
                    </View>

                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "#b7edc2", marginTop: 16}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Pós-pago</Text></View>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Q. cartelas</Text></View>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Arrecadado</Text></View>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Comissão</Text></View>        
                    </View>
                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "white"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>Whatssapp</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>{this.state.report.cartelasWhatssapp}</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.whatssapp}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.cartelasWhatssapp * this.state.user.comissao}</Text></View> 
                    </View>
                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "#ebedeb"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>Site</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>{this.state.report.cartelasSite}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.site}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{fontSize: 16}}>R${this.state.report.cartelasSite * this.state.user.comissao}</Text></View> 
                    </View>
                    <View style={{...this.state.styles.row, width: '100%', backgroundColor: "white"}}>
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>Total</Text></View>   
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>{this.state.report.cartelasWhatssapp + this.state.report.cartelasSite}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>R${this.state.report.whatssapp + this.state.report.site}</Text></View> 
                        <View style={{display: 'flex' , flexDirection: 'row', justifyContent: 'center', width: '25%'}}> <Text style={{...this.state.styles.bold, fontSize: 16}}>R${(this.state.report.cartelasWhatssapp + this.state.report.cartelasSite) * this.state.user.comissao}</Text></View>   
                    </View>

                    {this.state.filteredOrders.map(order => 
                        
                        <View style={{height:40, ...this.state.styles.row, ...this.state.styles.mt16, width: '100%', borderBottomWidth: 1}}>
                            <View style={{height: '100%', width: '20%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                                <Text style={{...this.state.styles.bold}}>{order.cartelas.length}</Text>
                            </View>
                            <View style={{height: '100%', width: '20%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                                <Text style={{...this.state.styles.bold}}>R$: {order.cartelas.length * parseFloat(order.cartelas[0].valor).toFixed(2)}</Text>
                            </View>
                            <View style={{height: '100%', width: '20%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                                <Text style={{...this.state.styles.bold}}>R$: {order.cartelas.length * this.state.user.comissao}</Text>
                            </View>
                            <View style={{height: '100%', width: '20%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                                <Text style={{...this.state.styles.bold}}>Pedido : {order.codigo}</Text>
                            </View>
                            <View style={{height: '100%', width: '20%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                                <Text style={{...this.state.styles.bold}}>Via : {order.isPanfleto? "panfletagem" : order.isPixPayment? "pix" : "whatssapp"}</Text>
                            </View>
                        </View>
                        
                        )}
                </View>

                
            </NavBarSeller>
            )
    }
}
