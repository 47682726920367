import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View, TextInput, Text, Alert,Image} from 'react-native';
import Picker from '../components/Picker';
import ButtonComponent from '../components/ButtonComponent';
import { singupSellerClient } from '../services/ApiServices';
import { getData } from '../services/StorageService';
import NavBarSeller from '../components/NavBarSeller';
import user_icon from '../assets/images/icons/user-plus-solid.png';

export default class ClientSingUpSellerScreen extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            systemColor : 'white',
            currentUrl: "",
            currentSystem: "",
            phone: "",
            firstName: "",
            lastName: "",
            city: "",
            UF: "",
            isLoading: false
        }
    }

    componentDidMount(){
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState();
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
    }


    setPhone(value){
        if(value.length > this.state.phone.length){
            let subMask = "?? ????? ????";
            value.split('').map((character,index) => {
                if(character != ' ' && !isNaN(character)){
                    subMask = subMask.replace('?', character);
                    if(index == value.length - 1){
                        this.setState({phone : subMask.split('?')[0]});
                    }
                }

            });
        }
        else{
            this.setState({phone : value})
        }
    }

    submitForm(){
        this.setState({isLoading : true});
        if(this.state.firstName.length < 3){
            this.setState({isLoading : false});
            alert("Nome inválido!");
            Alert.alert("Nome inválido!");
        }
        else if(this.state.phone.length < 13){
            this.setState({isLoading : false});
            alert("Telefone inválido!");
            Alert.alert("Telefone inválido!");
        }
        else if(this.state.city.length < 3){
            this.setState({isLoading : false});
            alert("Cidade inválida!");
            Alert.alert("Cidade inválida!");
        }
        else if(this.state.UF.length < 2 || this.state.UF.length > 2){
            this.setState({isLoading : false});
            alert("Estado inválida!");
            Alert.alert("Estado inválida!");
        }
        else{
            singupSellerClient(this.state.currentUrl, {
                nome: this.state.firstName + " " + this.state.lastName, 
                telefone: this.state.phone, 
                cidade: this.state.city,
                estado: this.state.UF,
                tutorId: this.state.user._id,
            }).then(res => {
                this.setState({isLoading : false});
                alert(res.msg);
                Alert.alert(res.msg);
                this.setState({redirect : {status: true, value: res.redirect}});
            })
        }

    }

    render() {
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
            <View style={this.state.styles.container}>
            <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Cadastro de clientes</Text>
                <Image source={user_icon} style={{width : 32, height: 22, marginLeft: 12}} />
            </View>
            <View style={{...this.state.styles.container, padding : 32, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <Text style={{...this.state.styles.fs22, ...this.state.styles.bold}}>Cadastre seu cliente</Text>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} placeholder='WhatsApp'
                        onChangeText={(value) => {this.setPhone(value)}}
                        value={this.state.phone}
                        keyboardType="numeric"
                        maxLength={13}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Nome'
                        onChangeText={(value) => {this.setState({firstName : value})}}
                        value={this.state.firstName}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Sobrenome'
                        onChangeText={(value) => {this.setState({lastName : value})}}
                        value={this.state.lastName}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Cidade'
                        onChangeText={(value) => {this.setState({city : value})}}
                        value={this.state.city}
                        keyboardType="default"
                        maxLength={15}
                ></TextInput>
                <View style={{...this.state.styles.mt8, width: '100%',  height: 'auto' , ...this.state.styles.myShadow}} >
                    <Picker  isAutoComplete={true} placeHolder={"Escolher"} onValueChange={(value) => this.setState({UF: value})} arrayLabelValue={[
                        {label : "Acre", value :"AC"},
                        {label : "Alagoas", value :"AL"},
                        {label : "Amapá", value :"AP"},
                        {label:"Amazonas", value:"AM"},
                        {label:"Bahia", value:"BA"},
                        {label:"Ceará",value:"CE"},
                        {label:"Distrito Federal", value:"DF"},
                        {label:"Espírito Santo",value:"ES" },
                        {label:"Goiás" ,value:"GO"},
                        {label:"Maranhão" , value:"MA"},
                        {label:"Mato Grosso" , value:"MT"},
                        {label:"Mato Grosso do Sul",value:"MS"},
                        {label:"Minas Gerais", value:"MG"},
                        {label:"Pará", value:"PA"},
                        {label: "Paraíba" , value:"PB"},
                        {label: "Paraná" , value:"PR"},
                        {label: "Pernambuco" , value:"PE"},
                        {label: "Piauí", value:"PI"},
                        {label: "Rio de Janeiro", value:"RJ"},
                        {label:"Rio Grande do Norte" , value:"RN"},
                        {label: "Rio Grande do Sul", value:"RS"},
                        {label:"Rondônia", value:"RO"},
                        {label:"Roraima", value:"RR"},
                        {label: "Santa Catarina", value:"SC"},
                        {label: "São Paulo", value:"SP"},
                        {label: "Sergipe", value:"SE"},
                        {label:"Tocantins" , value:"TO"}
                    ]}></Picker>
                </View>
                <View style={{width: '100%', ...this.state.styles.mt8}}>
                    <ButtonComponent disabled={false} action={this.submitForm.bind(this)} variant="primary_filled">Confirmar </ButtonComponent>
                </View>
            </View>
            </View>
            </NavBarSeller>
            )
    }
}
