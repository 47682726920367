import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,Image, Text, TextInput, ScrollView} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import layers_icon from '../assets/images/icons/layer-group-solid.png';
import { getClientsBySeller } from '../services/ApiServices';
import { getData } from '../services/StorageService';
import NavBarSeller from '../components/NavBarSeller';
import Picker from '../components/Picker';
export default class ClientsSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            clients: [],
            filteredClientList: [],
            selectedTipo: null,
            searchClientName: "",
            isLoading : false
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            getClientsBySeller(res, this.state.user._id).then(res => {
                this.setState({clients: Array.isArray(res)? res : [], filteredClientList : Array.isArray(res)? res : []})
                this.setState({isLoading : false});
            })
        })
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        return url;
    }

    handlerFilterTipo(value) {
        let array = []

        if(value == null){

            array = this.state.clients;
        }
        else {
            this.state.clients.map(cliente => {
                if(value == "app" && cliente.isApp && !cliente.isPanfleto){
                    array.push(cliente)
                }
                else if(value == "panfleto" && cliente.isApp && cliente.isPanfleto){
                    array.push(cliente)
                }
                else if(value == "avulso" && !cliente.isApp){
                    array.push(cliente)
                }
            })
  
        }
        this.setState({filteredClientList: array, selectedTipo: value})
    }

    filterClientByName(value){
        this.setState({
            searchClientName : value,
            filteredClientList : this.state.clients.filter(client => client.nome.toLowerCase().includes(value.toLowerCase()))
        });
        
    }
    render() {
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Visualisar clientes</Text>
                    <Image source={layers_icon} style={{width : 22, height: 22, marginLeft: 12}} /> 
                </View>
            <View style={{padding: 12, width: '100%'}}>
                <ButtonComponent disabled={false} action={() => this.setState({redirect : {status: true, value: 'ClientSingUpSellerScreen'}})} variant="success_filled"> Cadastrar novo cliente </ButtonComponent>
            </View>
            <View style={{padding: 12, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <View style={{width: '70%'}}>
                    <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                            placeholder='Buscar cliente por nome'
                            onChangeText={(value) => {this.filterClientByName(value)}}
                            value={this.state.searchClientName}
                            keyboardType="default"
                            maxLength={15}
                    ></TextInput>           
                </View> 
                <View style={{marginTop: 4,marginLeft: 8,  width: 86, borderWidth: 1, borderColor: 'gray', borderRadius: '4px' }}>
                        <Picker  onValueChange={(itemValue, itemIndex) => { this.handlerFilterTipo(itemValue)}} 
                            placeHolder = {"Todos"}
                            arrayLabelValue={[
                                {label : "Afiliados", value :"app"},
                                {label:"Avulso", value:"avulso"},
                                {label:"Panfleto", value:"panfleto"}
                            ]}></Picker>
    
                </View>
            </View>

            <ScrollView>
            <View style={{...this.state.styles.col, padding: 12, width: '100%'}}>
                    {this.state.filteredClientList.map(client => 
                        <View style={{...this.state.styles.col, ...this.state.styles.mt8, padding: 6, ...this.state.styles.myShadow, height: 96, width: '100%'}}>
                            <Text style={{...this.state.styles.fs14, ...this.state.styles.bold}}>{client.nome}</Text>
                            <Text style={{...this.state.styles.fs14, ...this.state.styles.bold}}>{client.telefone}</Text>
                            <Text style={{...this.state.styles.fs14, ...this.state.styles.bold}}>{client.cidade} - {client.estado}</Text>
                            <Text style={{...this.state.styles.fs14, ...this.state.styles.bold}}>{client.isApp? client.isPanfleto? "cliente via panfleto" : "cliente via link de afaliados": "cliente avulso"}</Text>
                        </View>
                    )}
            </View>
            </ScrollView>
            </NavBarSeller>
            )
    }
}
