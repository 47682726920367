import React from "react";
import { View, Alert, StyleSheet } from "react-native";
import YoutubePlayer from "react-native-youtube-iframe";

export default class Orders extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          playing : false
      }
  }
 
 onStateChange(state){
    if (state === "ended") {
      setPlaying(false);
      Alert.alert("video has finished playing!");
    }
 }

 render(){
  const styles = StyleSheet.create({
    videoContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 256,
      paddingTop: 100
    }
  
  });
  
  return (
    <View style={styles.videoContainer}>
      <YoutubePlayer
        height={300}
        play={this.state.playing}
        videoId={this.props.hashLive}
        onChangeState={this.onStateChange.bind(this)}
      />
    </View>
  );
 }
}



