import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, TextInput, Text, Alert, Image} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import home_icon from '../assets/images/icons/house-solid-dark.png';
import { resetClientPass } from '../services/ApiServices';
import { getData } from '../services/StorageService';
export default class ResetPasswordScreen extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            currentUrl: "",
            currentSystem: "",
            email: "",
        }
    }

    
    componentDidMount(){
        
        getData("current_url").then(res => this.setState({currentUrl: res}));
        getData("current_system").then(res => {
            this.setState({currentSystem: res});
        });
    }

    submitForm(){
        if(this.state.email.length < 8 || !this.state.email.toLowerCase().includes("@icloud.com") && !this.state.email.toLowerCase().includes("@gmail.com") && !this.state.email.toLowerCase().includes("@hotmail.com") && !this.state.email.toLowerCase().includes("@outlook.com")){
            alert("E-mail inválido!");
            Alert.alert("E-mail inválido!");
        }
        else{
            resetClientPass(this.state.currentUrl, this.state.email).then(res => {
                window.screen ? alert(res.msg) : console.log(res.msg);
                Alert.alert(res.msg);
                this.setState({redirect : {status: true, value: res.redirect}});
            })
        }
        
    }

    render() {
        return (
            <View style={this.state.styles.viewPort}>
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
            <View style={this.state.styles.container}>
            <View style={{height:40, ...this.state.styles.row, ...this.state.styles.bgLight, ...this.state.styles.alignCenter}}>
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: ''}})}><Image source={home_icon} style={{width : 22, height: 22, marginLeft: 12}} /></TouchableOpacity> 
            </View>
            <View style={{...this.state.styles.container, padding : 32, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                <Text style={{...this.state.styles.fs22, ...this.state.styles.bold}}>Reset sua senha</Text>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                        placeholder='Email'
                        onChangeText={(value) => {this.setState({email : value})}}
                        value={this.state.email}
                        keyboardType="default"
                ></TextInput>
                <View style={{width: '100%', ...this.state.styles.mt8}}>
                    <ButtonComponent disabled={false} action={this.submitForm.bind(this)} variant="primary_filled">Enviar para o email</ButtonComponent>
                </View>
                <TouchableOpacity><Text style={{...this.state.styles.fs14,...this.state.styles.bold , ...this.state.styles.cDanger, ...this.state.styles.mt32}}>Sua senha será enviada para o email associado!</Text></TouchableOpacity>
            </View>
            </View>
            </View>)
    }
}
