import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity} from 'react-native';
import CardOrder from './CardOrder';
export default class ModalOrder extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
        }
    }

    render() {

        return (
          <TouchableOpacity onPress={this.props.onPress}  style={{...this.state.styles.container, position: 'absolute', zIndex: 3000, width: '100%', height: '100%', backgroundColor: 'rgba(1,1,1,0.3)'}}>
              <View style={{position: 'absolute',zIndex: 3001, top: 128,  width: '100%', height: 480, backgroundColor: 'white', borderRadius: 6, display: 'flex', flexDirection: 'column'}}>
                {this.props.modalOrder.cartelas.map(card => <CardOrder navigation={this.props.navigation} numbers={[]} order={this.props.modalOrder} currentCard={card}></CardOrder> )}
              </View>
            </TouchableOpacity>
        )
    }
}
